import { timeFormat } from './dateFormat';
import * as time from './timeCalc';

export const scheduleStatuses = {
    PENDING_INVITED: 'pending-invited',
    PENDING_ACCEPTED: 'pending-accepted',
    PENDING_USER_REJECTED: 'pending-user-rejected',
    PENDING_USER_INVITED: 'pending-user-invited',
    PENDING_DRAFT: 'pending',
    ASSIGNED: 'assigned',
};

export const getTimeFromShift = (shift, isStart) => {
    if (shift.source === 'absence' || shift.source === 'vacation') {
        return `${shift.for_date} ${time.minutesToTimeInput(isStart ? shift.start_time : (shift.end_time - 2), false)}:00`;
    }
    return `${shift.for_date} ${time.minutesToTimeInput(isStart ? shift.start_time : shift.end_time + 1, false)}:00`;
};

const transfromTimelogs = (timelogArr, dateTimeRules) => {
    let timelogEntries = [], timelogsToBeApproved = [], timelogStatus = null, hasLogFromDiffLocation = false;
    if (!timelogArr) return {
        timelogEntries,
        timelogsToBeApproved,
        timelogStatus,
        timelogCount: 0,
        timelogsToBeApprovedCount: 0,
        hasLogFromDiffLocation
    }

    timelogEntries = timelogArr.map(timelog => {
        if (!timelogStatus)
            timelogStatus = timelog.timelog_status;

        if (timelogStatus && timelogStatus !== timelog.timelog_status)
            timelogStatus = 'none';

        if (timelog.differentTask)
            hasLogFromDiffLocation = timelog.differentTask;

        return {
            timelogId: timelog.timelog_id,
            timelogFrom: timelog.timelog_from,
            timelogTo: timelog.timelog_to,
            timelogStatus: timelog.timelog_status,
            timelogTitle:
                `${timeFormat(timelog.timelog_from, dateTimeRules)} - ${timelog.timelog_status === 'draft' ? 'At work' :
                    timeFormat(timelog.timelog_to, dateTimeRules)}`,
            timelogLoggedFromDiffLocation: timelog.differentTask
        }
    })

    timelogsToBeApproved = timelogEntries.filter(ele => ele.timelogStatus !== 'draft' && ele.timelogStatus !== 'approved');

    return {
        timelogEntries,
        timelogsToBeApproved,
        timelogStatus,
        timelogCount: timelogEntries.length,
        timelogsToBeApprovedCount: timelogsToBeApproved.length,
        hasLogFromDiffLocation
    }
}

export const transformToSchedulerEvent = (shift, dateTimeRules) => {
    const { timelogEntries, timelogsToBeApproved, timelogCount,
        timelogStatus, timelogsToBeApprovedCount, hasLogFromDiffLocation } = transfromTimelogs(shift.timelogs, dateTimeRules);
    const event = {
        id: shift.id,
        source: shift.source,
        start: getTimeFromShift(shift, true),
        end: getTimeFromShift(shift, false),
        resourceId: shift.assignee_id || 0,
        title: `${timeFormat(shift.start_time, dateTimeRules)} - ${timeFormat(shift.end_time, dateTimeRules)}`,
        bgColor: '#D9D9D9',
        showPopover: true,
        projectName: shift.project_name,
        taskName: shift.task_name,
        addBonus: (shift && shift.data && shift.data.addBonus) || false,
        workLogStatus: ['approved', 'rejected'].includes(timelogStatus) ? timelogStatus : shift.work_log_status,
        forDate: shift.for_date,
        show_to_member: shift.show_to_member,
        resourceName: shift.assignee_name,
        employeeType: shift.employee_type,
        userGroup: shift.user_group,
        shiftCount: shift.shift_creation_count ? shift.shift_creation_count : 1,
        timelogTitle: !!timelogCount ?
            timelogCount === 1 ?
                timelogEntries[0].timelogTitle : 'Multiple entries'
            : !shift.assignee_id ? 'Pending' :
                time.compareDates(shift.for_date, new Date()) > 0 ? 'Scheduled' : 'Missing Log',
        timelogCount,
        timelogEntries,
        timelogsToBeApproved,
        timelogsToBeApprovedCount,
        hasLogFromDiffLocation
    };
    return event;
};

export const transformToSchedulerEventList = (input, dateTimeRules, isModalView = false) => {
    if (isModalView) return { list: input?.shifts?.length ? input.shifts.map((r) => transformToSchedulerEvent(r, dateTimeRules)) : [] };
    return {
        list: input.combined.map((r) => transformToSchedulerEvent(r, dateTimeRules)),
    };
};
export const transformToSchedulerAvailability = (availabilityData, dateTimeRules) => {
    const availability = {
        id: availabilityData.id,
        availability_type: availabilityData.availability_type,
        from: availabilityData.from >= 0 ? timeFormat(availabilityData.from, dateTimeRules) : '',
        to: availabilityData.to >= 0 ? timeFormat(availabilityData.to, dateTimeRules) : '',
        resourceId: availabilityData.user_id || 0,
        title: `${timeFormat(availabilityData.from, dateTimeRules)} - ${timeFormat(availabilityData.to, dateTimeRules)}`,
        bgColor: '#000',
        showPopover: true,
        for_date: availabilityData.for_date,
        resourceName: `${availabilityData.first_name} - ${availabilityData.last_name}`,
        source: "availability"
    };
    return availability;
};

export const transformToSchedulerAvailabilityList = (input, dateTimeRules) => {
    return {
        list: input.availability.map((r) => transformToSchedulerAvailability(r, dateTimeRules)),
    };
};


export const getStatusText = (entity) => {
    let statusText = 'Waiting for invites';
    if (!entity.status) entity.status = scheduleStatuses.PENDING_DRAFT;
    switch (entity.status) {
        case scheduleStatuses.PENDING_INVITED:
            statusText = 'Invitations sent';
            break;
        case scheduleStatuses.PENDING_ACCEPTED:
            statusText = 'Invitation accepted';
            break;
        case scheduleStatuses.PENDING_USER_REJECTED:
            statusText = 'Rejected by the user';
            break;
        case scheduleStatuses.PENDING_USER_INVITED:
            statusText = 'Invitation sent by the user';
            break;
        case scheduleStatuses.PENDING_DRAFT:
            statusText = 'Pending';
            break;
        case scheduleStatuses.ASSIGNED:
            statusText = 'Assigned';
            break;
        default:
            statusText = 'Pending';
    }
    return statusText;
};
