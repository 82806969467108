import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { filter, find } from 'lodash';

import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';

const ProjectsWidgetMobx = inject('projectStore', 'userStore')(withLocalization(observer(class ProjectsWidgetMobx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initted: false,
        }
    }

    componentDidMount() {
        const { mode, projectStore, _module, options } = this.props;
        const _user_id = options && options.user_id ? options.user_id : null;
        const { allowAll } = this.props;
        const workingMode = allowAll ? 'no-acl' : mode;
        projectStore.loadLookup(workingMode, '', _module, _user_id);
    }

    componentDidUpdate(prevProps) {
        const { applyOnFirstLoad, value, onChange, projectStore } = this.props;
        const { initted } = this.state;
        const { loadingLookup, currentProjectsLookup } = projectStore;
        const { mode, allowAll } = this.props;
        const workingMode = allowAll ? 'no-acl' : mode;
        if (
            (!loadingLookup &&
                currentProjectsLookup.all &&
                currentProjectsLookup.all.length &&
                applyOnFirstLoad &&
                !initted) ||
            (!loadingLookup &&
                currentProjectsLookup.all &&
                currentProjectsLookup.all.length &&
                prevProps.filterByUser != this.props.filterByUser)
        ) {
            const rows = this.prepareOptions(currentProjectsLookup[workingMode]);
            let row = find(rows, (row) => `${row.value}` === `${value}`);
            if (!row) {
                row = rows.length ? rows[0] : null;
            }
            this.setState({ initted: true });
            if (row) {
                onChange(row.value, row);
            }
        }
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        return inputValue;
    };

    prepareOptions = (array) => {
        const { allowAll, showOnlyCurrentPM, t, userStore, filterByUser, allLabel } = this.props;
        const { user_type, id: userId } = userStore.currentUser;
        let _array = filterByUser ? filter(array, (a) => a.assigned_users.indexOf(parseInt(filterByUser)) >= 0) : array;
        _array = _array
            ? _array.map((pm) => {
                return { value: `${pm.id}`, label: `${pm.number} | ${pm.fullname}` };
            })
            : [];
        if (allowAll) {
            _array.unshift({ value: '', label: t(allLabel || 'All') });
        }

        if (showOnlyCurrentPM && user_type === 'pm') {
            _array = [];
            if (array) {
                array.forEach((project) => {
                    if (project.assignee_id === userId) {
                        _array.push({ value: `${project.id}`, label: `${project.number} | ${project.fullname}` });
                    }
                });
            }
        }
        return _array;
    };

    render() {
        const { value, onChange, projectStore, disabled, label, error, mode, allowAll, t } = this.props;
        const { currentProjectsLookup } = projectStore;
        const workingMode = allowAll ? 'no-acl' : mode;
        const options = this.prepareOptions(currentProjectsLookup[workingMode]);
        const currentSelect = find(options, (o) => {
            if (allowAll && !value)
                return String(o.value) === String('') || String(o.value) === String('all')
            if (value === 'all')
                return String(o.value) === String('') || String(o.value) === String('all');
            return String(o.value) === String(value);
        });
        const inputView = false;

        return (
            <div className={`managers-widget ${inputView ? 'input-block' : ''}`}>
                {inputView && <span className="input-block__label">{label}</span>}
                <Select
                    options={options}
                    inputView={inputView}
                    error={error}
                    value={currentSelect}
                    isDisabled={disabled}
                    placeholder={t("Select project")}
                    onChange={(id) => onChange(id.value, id)}
                    style={{ width: '100%' }}
                />
            </div>
        );
    }
})));

export const ProjectsWidget = observer(class ProjectsWidget extends Component {
    render() {
        return <ProjectsWidgetMobx {...this.props} mode="managers" />;
    }
});
