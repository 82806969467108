import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import { confirmAlert } from 'react-confirm-alert';

//components
import Button from '../../../components/CustomButton/CustomButton.jsx';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import GenericList from '~/components/GenericList/GenericList';
import withRemoveDialog from '~/hoc/withRemoveDialog';

//utils
import { shortDateFormat, totalTimeFormat } from '~/utils/dateFormat';
import customerFeature from '~/utils/customerFeature';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
import StatusBox from '../../../elements/StatusBox';
import Tripletex from '../../../assets/img/tripletex.svg'
import Sync from '../../../assets/img/Sync.svg'

// Elements

class TasksList extends Component {

    constructor(props) {
        super(props);
        const { page, isEdit } = this.props.router.location.state || {};
        this.state = {
            page: page ? page : 0,
            isPageAddedOrEdited: isEdit ? isEdit : false
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handleTableButtonAction = (id, type) => {
        if (type === 'edit') this.props.router.navigate(`/admin/tasks/${id}`,
            { state: { tasks: id, page: this.state.page, isEdit: this.state.isPageAddedOrEdited } });
        if (type === 'remove') {
            return this.props.handleDelete(id, (id) => {
                return this.props.taskStore.remove(id).then((res) => {
                    this.props.taskStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
    };

    handleSyncWithIntegration = (id) => {
        const { taskStore, t, commonStore } = this.props
        const str = t(`Do You want to Sync Task with Tripletex`);
        confirmAlert({
            title: this.props.t('Confirm to Sync'),
            message: str,
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        return taskStore.saveIntoIntegration({id: id}).then((res2) => {
                            taskStore.resetLastListLoadTime();
                            commonStore.addNotification(t(res2.message), null, 'success');
                            return res2;
                        });
                    },
                },
                {
                    label: this.props.t('No'),
                    onClick: () => { },
                },
            ],
        })
    }

    renderTableActionButtons = (id, full, int_id) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        return (
            <div className="actions-center">
                {this.props.commonStore.config?.integration_details?.product === 'tripletex' ? int_id ?
                    <img src={Tripletex} width={20} height={20} alt='int' title={'Synced with Tripletex'} />
                    :
                    <Button icon_sm fill title={this.props.t("Sync with integration")} onClick={() => this.handleSyncWithIntegration(id)}>
                        <img src={Sync} alt='sync' />
                    </Button>
                    :
                    <></>
                }
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                    <img src={Edit} alt={"edit button"} />
                </Button>
                {(user_type === 'member' || (user_type === 'member' && full.assignee_id === userId) || allow) &&
                    !full.is_default_for_project && (
                        <Button
                            icon_sm_delete
                            fill
                            onClick={() => this.handleTableButtonAction(id, 'remove')}
                        >
                            <img src={Delete} alt="delete button" />
                        </Button>
                    )
                }
            </div>
        );
    };

    //handles generic list page change//
    handlePageChange = (page, isEdit) => {
        this.setState({ page, isPageAddedOrEdited: isEdit });
    };


    render() {
        const { page, isPageAddedOrEdited } = this.state;
        const { loading, filters, appliedFilters } = this.props.taskStore;
        const { config } = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data
            && config.client.data.dateTimeRules
            ? config.client.data.dateTimeRules : false;
        const statusOptions = config?.client?.data?.task_statuses || [];
        return (
            <>
                <div className="table-list">
                    <div className="table-list__header"></div>
                    <GenericList
                        columns={[
                            {
                                Header: 'Task',
                                id: 'name',
                                accessor: ({ id, name, task_number }) => (
                                    <span title={`${task_number} | ${name}`}>
                                        <a
                                            className="list-main-column"
                                            onClick={() => this.handleTableButtonAction(id, 'edit')}
                                        >
                                            {`${task_number} | ${name}`}
                                        </a>
                                    </span>
                                ),
                                isSortable: true,
                                maxWidth: 120,
                                minWidth: 80,
                                textAlign: "start"
                            },
                            {
                                Header: 'Project',
                                id: 'project_name',
                                accessor: ({ project_name }) => <span>{project_name}</span>,
                                isSortable: true,
                                maxWidth: 120,
                                minWidth: 80
                            },
                            {
                                Header: 'Assignee',
                                id: 'user_name',
                                accessor: ({ user_name, is_default_for_project }) => (
                                    <span title={is_default_for_project ? this.props.t('All') : `${user_name || ''}`}>
                                        {is_default_for_project ? this.props.t('All') : `${user_name || ''}`}
                                    </span>
                                ),
                                isSortable: true,
                                maxWidth: 80,
                                minWidth: 60
                            },
                            {
                                Header: 'Status',
                                id: 'status',
                                accessor: ({ status }) => <StatusBox status={status || ''} />,
                                maxWidth: 70,
                                minWidth: 50
                            },
                            {
                                Header: 'Location',
                                id: 'location',
                                sortable: false,
                                accessor: ({ address, post_place, post_number }) => (
                                    <span>
                                        {`${address ? `${address} ` : ''}${post_place ? `${post_place} ` : ''}${post_number ? `${post_number} ` : ''}`}
                                    </span>
                                ),
                                maxWidth: 120,
                                minWidth: 80
                            },
                            {
                                Header: 'Last Updated',
                                id: 'updated_at',
                                accessor: ({ updated_at }) => (
                                    <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                                ),
                                isSortable: true,
                                maxWidth: 80,
                                minWidth: 160
                            },
                            {
                                Header: 'Total Hours',
                                id: 'total_hours',
                                sortable: false,
                                accessor: ({ total_hours }) => `${totalTimeFormat(total_hours, dateTimeRules)}`,
                                maxWidth: 80,
                                minWidth: 60
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: (full) => this.renderTableActionButtons(full.id, full, full.int_id),
                                maxWidth: 120,
                                minWidth: 80
                            },
                        ]}
                        header={
                            <GenericFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.taskStore.setFilter(name, value)}
                                onFilter={() => this.props.taskStore.onFilter()}
                                loading={loading}
                                hasStatusFilter={true}
                                statusOptions={statusOptions}
                                hasProjectFilter={true}
                            />
                        }
                        tableHeader={this.props.t('Tasks')}
                        forceReload={this.props.taskStore.deleteSuccess}
                        filters={appliedFilters}
                        lastListLoadTime={this.props.taskStore.lastListLoadTime}
                        requestData={(params) => this.props.taskStore.loadList(params)}
                        handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                        page={page}
                        isPageAddedOrEdited={isPageAddedOrEdited}
                    >
                        <Button fill wd onClick={() => this.props.onAdd(page, isPageAddedOrEdited)}>
                            <i className="fa fa-plus-circle fa-xl margin-right-10" />
                            {this.props.t('Add new')}
                        </Button>
                    </GenericList>
                </div>
            </>
        );
    }
}
export default inject(
    'taskStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(TasksList)))));
