import React, { Component, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { Row, Modal } from 'react-bootstrap';

//components
import withLocalization from '~/hoc/withLocalization';
import GenericForm from '~/components/GenericForm/GenericForm';

//utils
import getSchema from '~/library/schemas/extraPayments';
import { extraPaymentWageTypes } from '~/utils/constants'

//assets
import CloseModalIcon from '~/assets/img/CloseApproveModalIcon.svg';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            name: { xs: 6, sm: 6, className: "mt-3" },
            isActive: { xs: { span: 4, offset: 2 }, sm: 3, className: "mt-3" },
        },
        {
            extraPaymentType: { xs: 4, className: "mt-3" },
            amount: { xs: 4, className: "mt-3" },
            payrollWageType: { xs: 4, className: "mt-3" },
        },
        {
            userGroups: { xs: 6, className: "mt-3" },
            employmentTypes: { xs: 6, className: "mt-3" },
        },
        {
            fullDay: { xs: 4, className: "mt-4" },
            fromTime: { xs: 4, className: "mt-3" },
            toTime: { xs: 4, className: "mt-3" },
            daysOfWeek: { xs: 12, sm: 9, className: "mt-3" },
        },
        {
            description: { xs: 12, className: "my-2" },
        },
    ],
    name: {
        'ui:placeholder': 'Enter additional payment name',
    },
    isActive: {
        'ui:widget': 'ActiveInactive',
        'ui:options': {
            valueInBool: true
        }
    },
    fullDay: {
        'ui:widget': 'Radio',
    },
    fromTime: {
        'ui:widget': 'InputTime',
        'ui:options': {
            valueInInt: true
        }
    },
    toTime: {
        'ui:widget': 'InputTime',
        'ui:options': {
            valueInInt: true
        }
    },
    daysOfWeek: {
        'ui:widget': 'DaysOfWeek',
        'ui:options': {
            includeCheckAll: true
        }
    },
    userGroups: {
        'ui:widget': 'SimpleMultiSelectWidget',
        'ui:options': {}
    },
    description: {
        'ui:widget': 'textarea',
        'ui:placeholder': 'Add a brief description for additional payment rule'
    }
};

class ExtraPaymentsForm extends Component {
    constructor(props) {
        super(props);
        this.wagetypes = props.commonStore.config?.integration_details?.wageType ?
            props.commonStore.config.integration_details.wageType : [];
        this._userGroups = props.commonStore.config?.client?.data?.extendedRules?.user_groups?.length ?
            props.commonStore.config?.client?.data?.extendedRules?.user_groups
            : [{ code: 'all', name: 'All Groups' }];
        this.state = {
            schema: getSchema({
                payrollWagetypes: this.wagetypes, code: props.entity?.code,
                isNotDeletable: props.entity.isNotDeletable,
                translate: props.t
            }),
            uiSchema: defaultUiSchema
        };
    }

    validateExtraPayment(formData, errors) {
        if (formData) {
            const { t, existingRules } = this.props;
            if (formData?.name && existingRules.includes(formData.name.replace(/\s/g, '').toLowerCase())) {
                errors.name.addError(t('Name already in use, please add unique name'))
            }
            if (!formData?.daysOfWeek) {
                errors.daysOfWeek.addError(t('Please select atleast one day!'))
            }
            if (!formData?.userGroups) {
                errors.userGroups.addError(t('Please select atleast one group!'))
            }
            if (!formData?.fullDay && !formData?.fromTime && !formData?.toTime) {
                errors.fromTime.addError(t('Please select from time or to time'))
                errors.toTime.addError(t('Please select from time or to time'))
            }
        }
        return errors;
    }

    onChangeHandler(formData) {
        const { schema } = this.state;
        if (schema?.properties?.fromTime['readOnly'] !== formData?.fullDay) {
            let _schema = cloneDeep(schema);
            _schema.properties.fromTime['readOnly'] = !!formData?.fullDay;
            _schema.properties.toTime['readOnly'] = !!formData?.fullDay;
            formData.fromTime = 0;
            formData.toTime = 1439;
            this.setState({ schema: _schema });
        }
        return formData;
    }

    onSave(values) {
        const isAllGroups = values.userGroups.split(',').length === this._userGroups.length;
        const data = {
            code: values.code ? values.code : values.name.replace(/\s/g, '').toLowerCase(),
            name: values.name,
            extraPaymentType: extraPaymentWageTypes.find(ele => ele.value === values.extraPaymentType),
            payrollWageType: this.wagetypes.find(ele => ele.value === values.payrollWageType),
            amount: values.amount,
            fullDay: values.fullDay,
            fromTime: values.fromTime,
            toTime: values.toTime,
            daysOfWeek: values.daysOfWeek,
            userGroups: isAllGroups ? "all" : values.userGroups,
            employmentTypes: values.employmentTypes,
            description: values.description?.trim() ? values.description : values.name,
            isNotDeletable: !!values.isNotDeletable,
            isActive: !!values.isActive
        }
        this.props.handleUserDataChange(data);
    }

    getBack = () => {
        if (this.props.getBack)
            this.props.getBack()
    }

    static formatEntity = function (entity, groups) {
        const formattedEntity = {
            ...entity, payrollWageType: entity?.payrollWageType?.value || 'null',
            extraPaymentType: entity?.extraPaymentType?.value || 'amount',
            userGroups: entity.userGroups === 'all' || !entity.userGroups ?
                groups.map(ele => ele.code).join(',') : entity.userGroups,
        }
        return formattedEntity;
    }

    static prepareSchema = function (_schema, _userGroups, code, t) {
        const __schema = cloneDeep(_schema)
        __schema.userGroups = {
            'ui:widget': 'SimpleMultiSelectWidget',
            'ui:options': {
                options: _userGroups.map(ele => { return { label: ele.name, value: ele.code } }),
                overrideStrings: {
                    selectSomeItems: t('Select Groups'),
                    allItemsAreSelected: t('All groups are selected'),
                    search: t('Search'),
                    selectAll: t('Select All'),
                    noOptions: t("No groups are available, you can add them in the control panel under 'Extended Rules' and 'User Groups'")
                }
            }
        }
        __schema.description = {
            'ui:widget': 'textarea',
            'ui:placeholder': t('Add a brief description for additional payment rule')
        }
        switch (code) {
            case 'weekends': {
                __schema.daysOfWeek = {
                    'ui:widget': 'DaysOfWeek',
                    'ui:options': {
                        includeCheckAll: true
                    },
                    'ui:help': t("Please make sure to keep weekend days same as default holidays under basic rules")
                }
                __schema.name = {
                    'ui:placeholder': t('Enter additional payment name'),
                    'ui:help': t("This is a defualt addtional payment, so few settings will be disabled as they can't be overwritten")
                }
                break;
            }
            case 'emergency_shifts': {
                __schema.name = {
                    'ui:placeholder': t('Enter additional payment name'),
                    'ui:help': t("This is a defualt addtional payment, so few settings will be disabled as they can't be overwritten")
                }
                __schema.userGroups['ui:help'] = t("Assign emergency shift(On-call) ability to employee from schedule");
                __schema.employmentTypes = {
                    'ui:help': t("Assign emergency shift(On-call) ability to employee from schedule")
                }
                __schema.fromTime = {
                    'ui:widget': 'InputTime',
                    'ui:options': {
                        valueInInt: true
                    },
                    'ui:help': t("Please choose time after company hours")
                }
                __schema.toTime = {
                    'ui:widget': 'InputTime',
                    'ui:options': {
                        valueInInt: true
                    },
                    'ui:help': t("Please choose time after company hours")
                }
                __schema.isActive = {
                    'ui:widget': 'ActiveInactive',
                    'ui:options': {
                        valueInBool: true
                    },
                    'ui:help': t('Activate emergency shift(On-call) and assigne to employees with shift from schedule screen')
                }
                break;
            }
            case 'shift_allowance': {
                __schema.name = {
                    'ui:placeholder': t('Enter additional payment name'),
                    'ui:help': t("This is a defualt addtional payment, so few settings will be disabled as they can't be overwritten")
                }
                __schema.userGroups['ui:help'] = t("Assign shift allowance to employee from schedule");
                __schema.employmentTypes = {
                    'ui:help': t("Assign shift allowance to employee from schedule")
                }
                __schema.isActive = {
                    'ui:widget': 'ActiveInactive',
                    'ui:options': {
                        valueInBool: true
                    },
                    'ui:help': t('Activate Shift Allowance and use it from schedule screen')
                }
                break;
            }
            default:
                break;
        }
        return __schema;
    }

    render() {
        const { schema } = this.state;
        return (
            <GenericForm
                entity={ExtraPaymentsForm.formatEntity(this.props.entity, this._userGroups)}
                uiSchema={ExtraPaymentsForm.prepareSchema(defaultUiSchema, this._userGroups, this.props.entity.code, this.props.t)}
                schema={schema}
                translationScope="forms.extraPayments"
                onSave={(values, redirect) => this.onSave(values, redirect)}
                onChange={(fd) => this.onChangeHandler(fd)}
                customvalidate={(formData, errors) => this.validateExtraPayment(formData, errors)}
                listUrl={"/"}
                goBack={() => this.getBack()}
            />
        );
    }
}


const ExtraPaymentsFormWithModal = (props) => {
    const [modalOpen, setModalOpen] = useState(props.modalOpen);
    useEffect(() => {
        setModalOpen(props.modalOpen);
    }, [props.modalOpen])
    const closeModal = function () {
        props.closeModal()
    }
    return (
        <Modal
            className={`${"modal right fade modal_revised"}`}
            backdrop="static"
            show={modalOpen}
            onHide={closeModal}
        >
            <Modal.Header>
                <Modal.Title className="w-100 text-center m-0">{props.t('Additional Payments')}</Modal.Title>
                <img
                    src={CloseModalIcon}
                    className="cursor-pointer"
                    alt='close_button'
                    onClick={closeModal}
                />
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <ExtraPaymentsForm
                        entity={props.entity || {}}
                        handleUserDataChange={props.handleUserDataChange}
                        getBack={closeModal}
                        {...props}
                    />
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default inject('commonStore')(withLocalization(observer(ExtraPaymentsFormWithModal)));
