import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import { Modal, Button, NavDropdown, Dropdown } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

//components
import withLocalization from '../../../../../hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import AgreementModal from '../../../../../containers/Admin/SignDocs/Recipients/public/agreementModal';

//elements
import LoadingSpinner from '../../../../../elements/LoadingSpinner';
import Input from '../../../../../elements/Input';
import SignWidget from '../../Widgets/SignWidget';
import TextboxWidget from '../../Widgets/TextboxWidget';
import InitialWidget from '../../Widgets/InitialWidget';
import DateWidget from '../../Widgets/DateWidget';
import SelectBoxWidget from '../../Widgets/SelectBoxWidget';
import CheckBoxWidget from '../../Widgets/CheckBoxWidget';
import RadioWidget from '../../Widgets/RadioWidget';
import LabelWidget from '../../Widgets/LabelWidget';
import RecipientNameWidget from '../../Widgets/RecipientNameWidget';

//styles
import '../public/index.css';

//services
import config from '~/library/config';

//assets
import Logo from '../../../../../assets/img/Logo.webp';
import Download from '../../../../../assets/img/download-icon.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { defaultLanguage } from '~/library/core';

export class ReviewAndSignDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docPages: null,
            recipient: null,
            signed_recipient: null,
            widgets: null,
            isAgreed: true,
            caseId: null,
            zoomScale: 1,
            accessCode: null,
            isVerified: false,
            getResponse: null,
        };
    }

    componentDidMount() {
        const { signatureStore, propertyStore } = this.props;
        const search = this.props.router.location.search;
        const id = search.slice(4);
        if (id) {
            signatureStore.getRecipientDoc(id).then((res) => {
                if (res.status === 'expired') {
                    this.props.router.navigate('/docexpire');
                } else if (res.status === 'deleted') {
                    this.props.router.navigate('/docdeleted');
                } else {
                    propertyStore.addWidget(res.sign_document.widgets);
                    signatureStore.setCaseId(res.sign_document.id);
                    this.setState({
                        recipient: res.recipient_detail,
                        getResponse: res,
                        isAgreed: res.isPolicyAgreed,
                        caseId: res.sign_document.id,
                        isVerified: res.isPolicyAgreed,
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.widgets !== this.state.widgets) {
            if (window.screen.width < 500) {
                document.querySelector(".signerPage").style.zoom = "50%"
            }
        }
    }

    handleLangChange = lang => {
        this.props.commonStore.setAppLang(lang);
        // Force Render
        this.setState({ state: this.state });
    };
    handleAccessCode(event) {
        const { signatureStore } = this.props;
        this.setState({access_code:event.target.value},()=>{
            signatureStore.VerifyCode(event.target.value);
        })
    }

    handleAgreement = () => {
        const { recipientStore } = this.props;
        recipientStore.recipientAgreement({ case_id: this.state.caseId, recipient_id: this.state.recipient.id });
        this.setState({ isAgreed: true, isVerified: true });
    };

    verifyCode() {
        const { signatureStore,commonStore, t } = this.props;
        const { getResponse } = this.state;
        const search = this.props.router.location.search;
        const url_id = search.slice(4);
        const data = {
            id: url_id,
            access_code: signatureStore.accessCode,
        };
        if ((signatureStore.accessCode).length > 6) {
            commonStore.addNotification(t('Access code should be of only 6 letter'), null, 'error');
        }

        signatureStore
            .verifyAccessCode(data)
            .then((res) => {
                if (res.status === 'Verified') {
                    this.setState({
                        isVerified: false,
                        docPages: getResponse.document_pages,
                        widgets: getResponse.sign_document.widgets
                    });
                }
            })
    }

    handleContinue = () => {
        this.setState({ isAgreed: true });
    }
    
    getWidgetFromData(widget, pageId) {
        const { recipient, caseId } = this.state;
        if (widget.pageId === pageId) {
            if (widget.name === 'Signature') {
                if (widget.recipientId !== recipient.id && widget.data) {
                    let copyWidget = {...widget}
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else {
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            edit={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                }
            } else if (widget.name === "Text") {
                if (widget.recipientId !== recipient.id) {
                    return <TextboxWidget key={widget.widgetId} info={widget} view={1} locked={true} />
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return <TextboxWidget key={widget.widgetId} info={widget} view={1} locked={true} />;
                } else {
                    return <TextboxWidget key={widget.widgetId} info={widget} edit={1} locked={true} />;
                }
            } else if (widget.name === "Date") {
                if (widget.recipientId !== recipient.id) {
                    return <DateWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                } else if (widget.recipientId === recipient.id && recipient.status === "signed") {
                    return <DateWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                } else {
                    return <DateWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === 'Initial') {
                if (widget.recipientId !== recipient.id && widget.data) {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            edit={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                }
            } else if (widget.name === 'Label') {
                if (widget.recipientId !== recipient.id) {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === "Recipient") {

                if (widget.recipientId !== recipient.id) {
                    return (
                        <RecipientNameWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                    );
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return (
                        <RecipientNameWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                    );
                } else {
                    return <RecipientNameWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === "Select") {

                if (widget.recipientId !== recipient.id && widget.data) {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            } else if (widget.name === "Checkbox") {

                if (widget.recipientId !== recipient.id) {
                    return <CheckBoxWidget key={widget.widgetId} disable={true} info={widget} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <CheckBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <CheckBoxWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            } else if (widget.name === 'Radio') {
                if (widget.recipientId !== recipient.id) {
                    return <RadioWidget key={widget.widgetId} disable={true} info={widget} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <RadioWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <RadioWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            }
            else {
                return null
            }
        } else {
            return null;
        }
    }

    zoomIn = () => {
        if (this.state.zoomScale >= 1.5) return;
        this.setState({ zoomScale: this.state.zoomScale + 0.1 });
    };
    zoomOut = () => {
        if (this.state.zoomScale <= 1) return;
        this.setState({ zoomScale: this.state.zoomScale - 0.1 });
    };
    handleFinish = () => {
        const { signatureStore, propertyStore, commonStore, t,} = this.props;
        let flag = 0;
        let widgetsData = {
            caseId: signatureStore.caseId,
            widgets: propertyStore.widgets
        }
        for (let i = 0; i < propertyStore.widgets.length; i++) {
            if (propertyStore.widgets[i].recipientId === this.state.recipient.id && propertyStore.widgets[i].required && !propertyStore.widgets[i].data) {
                flag = 1;
                break;
            }
        }
        if (flag) {
            commonStore.addNotification(t('Please add signature widget for all recipient'), null, 'error');
            return
        }
        const search = this.props.router.location.search;
        const id = search.slice(4);
        propertyStore.saveWidget(widgetsData)
        signatureStore.getByDocCaseId(id).then((res) => {
            this.props.router.navigate("/signsuccess");
        })
    }

    handleDownload = () => {
        const { signatureStore, propertyStore} = this.props;
        const search = this.props.router.location.search;
        const id = search.slice(4);
        let widgetsData = {
            caseId: signatureStore.caseId,
            widgets: propertyStore.widgets
        }
        propertyStore.saveWidget(widgetsData)
        window.open(`${config.API_ROOT}/sign_documents/download/${encodeURIComponent(id)}`);
    }
    langName = (lang) => {
        if (lang == 'no') return 'Norsk';
        if (lang == 'en') return 'English';
        return defaultLanguage();
    };

    render() {
        const { docPages, recipient, caseId } = this.state;
        const { signatureStore, t } = this.props;
        return (
            <div className="signerPage">
                <ToastContainer />
                <div className='nav-signer'>
                    <div className="header">
                        <div className="header-details-nav">
                            {
                                docPages && (
                                    <div className="footernav">
                                        <h4>{t('Review Document and Sign It')}</h4>
                                        <NavDropdown
                                            title={(
                                                <div className="lp-text-btn">
                                                    <i className="fa fa-globe" />
                                                    {this.langName(this.props.commonStore.locale)}
                                                </div>
                                            )}
                                            
                                            id="basic-nav-dropdown-1"
                                           className='language-dropdown mt-3'
                                        >
                                            <Dropdown.Item onClick={() => this.handleLangChange('en')}>English</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.handleLangChange('no')}>Norsk</Dropdown.Item>
                                        </NavDropdown>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
                <div className="header mt-3">
                    {
                        docPages && (
                            <div className="header-details">
                                <h6>{recipient && recipient.user_name}</h6>
                                <p>{recipient && recipient.email}</p>
                            </div>
                        )
                    }
                </div>
                <div>
                    {
                        signatureStore.isDocFinished ?
                            <LoadingSpinner /> :
                            <div className='content'>
                                {
                                    docPages && docPages.map((page) => {
                                        return (
                                            <>
                                                <div className="sign-img" key={uuid()}>
                                                    <LazyLoadImage src={`${config.UPLOADS_ROOT}/${page.page_path}`} draggable="false" />
                                                    {this.state.widgets && this.state.widgets.map((widget) => this.getWidgetFromData(widget, page.id))}
                                                </div>
                                                <div className="devider" key={uuid()}></div>
                                            </>
                                        );
                                    })
                                }
                            </div>
                    }
                </div>
                {docPages && recipient && recipient.status !== 'signed' && (
                    <footer>
                        <div className="footer-items">
                            <img src={Logo} />
                            {
                                signatureStore.isDocFinished ?
                                    <img
                                        src={Download}
                                        style={{
                                            background: 'rgb(235, 243, 252)',
                                            width: '30px',
                                            height: '32px',
                                            padding: '5px',
                                            marginLeft: '35%',
                                            cursor: 'not-allowed',
                                            display: 'block'
                                        }}
                                    />
                                    :
                                    <img
                                        src={Download}
                                        onClick={this.handleDownload}
                                        style={{
                                            background: 'rgb(235, 243, 252)',
                                            width: '30px',
                                            height: '32px',
                                            padding: '5px',
                                            marginLeft: '35%',
                                            cursor: 'pointer',
                                            display: 'block'
                                        }}
                                    />
                            }

                            {recipient.signing_type !== 'Send Copy' && (
                                <button className="btn btn-fill ml-auto finishBtn" disabled={signatureStore.isDocFinished} onClick={this.handleFinish} style={{ marginRight: "20px" }}>
                                    {t('Finish')}
                                </button>
                            )}
                        </div>
                    </footer>
                )}

                <AgreementModal
                    show={!this.state.isAgreed}
                    handleAgreement={this.handleAgreement}
                    user={recipient && recipient.user_name}
                    email={recipient && recipient.email}
                />

                <Modal className="VerificationModal" show={this.state.isVerified}>
                    <Modal.Header className='justify-content-center'>
                        <Modal.Title>{t('Access Verification')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='verification-main'>
                            <div style={{ marginTop: '20px' }}>
                                <Input
                                    placeholder={this.props.t("Enter Code")}
                                    onChange={(e) => this.handleAccessCode(e)}
                                    type="number"
                                    value={this.state.access_code}
                                />
                            </div>
                            <Button
                                className="btn btn-fill"
                                type="button"
                                style={{ marginTop: '15px' }}
                                onClick={() => {
                                    this.verifyCode();
                                }}
                            >
                                {t('Verify')}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default inject('commonStore', 'propertyStore', 'signatureStore', 'recipientStore')(applicationRouter(withLocalization(observer(ReviewAndSignDoc))));
