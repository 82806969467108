import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '../../../hoc/withLocalization';
import withLocalization from '../../../hoc/withLocalization';

//elements
import Select from '../../../elements/Select';
import CustomMultiSelect from '../../../elements/CustomMultiSelect';

const SimpleSelectWithMobx = inject('commonStore')(applicationRouter(withLocalization(observer(class SimpleSelect extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(id, _returnObject) {
        const { onChange, multiSelect } = this.props;
        if (multiSelect) {
            if (_returnObject) return onChange(id);
            const commaSeperatedIds = id.map(ele => ele.value).join(',')
            return onChange(commaSeperatedIds)
        }
        onChange(id)
    }

    render() {
        const { value, options, disabled, multiSelect, returnObject = false, schema, overrideStrings = {}, styleClasses } = this.props;
        const __options = options?.options ? options.options : options;
        const __returnObject = options?.returnObject !== undefined ? !!options.returnObject : !!returnObject;
        const __disabled = schema?.readOnly !== undefined ? !!schema.readOnly : !!disabled;
        const __overrideStrings = options?.overrideStrings ? options.overrideStrings : overrideStrings;
        return (
            <div className={multiSelect ? '' : 'managers-widget'}>
                {multiSelect && options ?
                    <CustomMultiSelect
                        options={__options}
                        value={value || []}
                        onChange={(selected) => this.onChange(selected, __returnObject)}
                        returnObject
                        disabled={__disabled}
                        overrideStrings={__overrideStrings}
                    />
                    : <Select
                        options={__options}
                        value={value}
                        isDisabled={__disabled}
                        onChange={id => this.onChange(id, false)}
                        style={{ width: '100%' }}
                        className={styleClasses}
                    />}
            </div>
        );
    }
}))));


export const SimpleSelectWidget = observer(class SimpleSelectWidget extends Component {
    render() {
        return <SimpleSelectWithMobx {...this.props} />
    }
})

export const SimpleMultiSelectWidget = observer(class SimpleMultiSelectWidget extends Component {
    render() {
        return <SimpleSelectWithMobx {...this.props} multiSelect />
    }
})



