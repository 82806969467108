import { observable, action, makeObservable } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import _ from 'lodash';

import agent from '../agent';

let projectAddressThrottleTimer = null;

class ProjectStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            currentProjectsLookup: observable,
            loadingLookup: observable,
            gpsNowLoading: observable,
            filters: observable,
            appliedFilters: observable,
            setFilter: action,
            onFilter: action,
            lastListLoadTime: observable,
            resetLastListLoadTime: action,
            loadList: action,
            returnDefaultNew: action,
            load: action,
            getProjectMembersIds: action,
            save: action,
            remove: action,
            loadLookup: action,
            performRecalcGpsForProject: action,
            recalcGpsForProject: action,
            saveIntoIntegration: action,
            saveProjectFromIntegration: action,
        });
    }

    currentList = [];
    currentEntity = {};
    updatingErrors = null;
    updating = false;
    loading = false;
    requestParams = null;
    deleteSuccess = false;
    currentProjectsLookup = {};
    loadingLookup = false;
    gpsNowLoading = false;
    filters = {
        status: 'active',
        name: '',
    };
    appliedFilters = {
        status: 'active',
        name: '',
    };


    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    lastListLoadTime = null;

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    loadList(params) {
        return agent.Projects.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    returnDefaultNew(params) {
        this.currentEntity = { project: { name: null, data: {} } };
        this.loading = false;
    }

    load(id) {
        this.loading = true;
        return agent.Projects.load(id)
            .then(
                action(response => {
                    if (!response.project.data) response.project.data = {};
                    this.currentEntity = response;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    getProjectMembersIds(id) {
        this.loading = true;
        return agent.Projects.getProjectMembersIds(id)
            .then(
                action(response => {
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        this.updating = true;
        return agent.Projects.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async remove(id, confirm) {
        const res = await agent.Projects.remove(id, confirm);
        if (confirm) {
            this.deleteSuccess = true;
            return 1;
        }
        return res;
    }

    async loadLookup(_mode, name, __module, _user_id = null) {
        const mode = _mode || 'all';
        const _module = __module || "no-module";
        this.loadingLookup = true;
        const list = await agent.Projects.lookupByName(mode, name, _module, _user_id);
        this.currentProjectsLookup[mode] = list;
        this.loadingLookup = false;
        return list;
    }

    async performRecalcGpsForProject(newAddress) {
        this.gpsNowLoading = true;
        this.currentEntity.project.data.addressCache = newAddress;
        const tsk = _.cloneDeep(this.currentEntity.project);
        try {
            const data = await agent.Tasks.gpsAddress(newAddress);
            tsk.gps_data = data.result;
            tsk.data.addressCache = newAddress;
            this.currentEntity = { project: tsk };
            this.gpsNowLoading = false;
        } catch (e) {
            this.gpsNowLoading = false;
            throw e;
        }
    }

    async recalcGpsForProject(changes) {
        const entityToAddressString = e => {
            return `${e.address}, ${e.post_place}, ${e.post_number}`;
        };

        if (!changes.address || !changes.post_place) {
            // || !changes.post_number
            // not filled
            return changes;
        }

        const newAddress = entityToAddressString(changes);

        if (newAddress === this.currentEntity.project.data.addressCache) return changes;

        projectAddressThrottleTimer = new Date().getTime();
        setTimeout(() => {
            // throttle
            if (new Date().getTime() - projectAddressThrottleTimer < 1000) {
                return;
            }
            if (this.currentEntity.project.data.addressCache === newAddress) {
                return;
            }
            this.performRecalcGpsForProject(newAddress);
        }, 1000);
        return changes;
    }

    saveIntoIntegration(values) {
        return agent.Projects.saveIntoIntegration(values)
            .then(
                action(project => {
                    return project;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    getAllProjectFromIntegration() {
        return agent.Projects.getAllProjectFromIntegration().then(
            action(projectlist => {
                return projectlist.list
            })
        ).catch(
            action(err => {
                this.updating = false;
                this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                throw err;
            })
        );
    }

    saveProjectFromIntegration(data) {
        return agent.Projects.saveProjectFromIntegration(data).then(
            action(res => {
                return res
            })
        ).catch(
            action(err => {
                this.updating = false;
                this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                throw err;
            })
        )
    }
}

const _ProjectStore = new ProjectStore();
export default _ProjectStore;
