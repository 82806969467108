import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';
import { Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import BasicRules from './BasicRules/BasicRules';
import ExtendedRules from './ExtendedRules/ExtendedRules';
import VacationRules from './VacationRules/VacationRules';
import LoginRules from './LoginRules/LoginRules';
import ProjectRules from './ProjectRules/ProjectRules';
import TaskRules from './TaskRules/TaskRules';
import DeviationRules from './DeviationRules/DeviationRules';
import TipRules from './TipRules/TipRules';
import DateTimeRules from './DateTimeRules/DateTimeRules';
import RoleTypes from './RoleTypes/RoleTypes';
import Integrations from './Integrations/Integrations';
import ExtraPayments from './ExtraPayments/ExtraPayments';
// import RolesAndPermission from './RolesAndPermissions/RolesAndPermissions';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

class ControlPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            isUpdating: false,
        };
    }

    static oldClientData = {};

    static setOldClientData = function (data) {
        ControlPanel.oldClientData = data;
    }

    componentDidMount() {
        this.loadData();
        this.completeSettings();
    }

    componentWillMount() {
        this.props.clientStore.setLoading(true);
    }

    loadData = async () => {
        const { clientStore, userStore } = this.props;
        await clientStore.load(userStore.currentUser.client_id, false).then(
            (res) => {
                this.setState(prevState => ({ isLoad: !prevState.isLoad }));
                ControlPanel.setOldClientData(res.client.data);
            });
    }

    saveClientsConfig = (hasUpdatedWeekendsPayment) => {
        const { clientStore, commonStore, t } = this.props;
        const currentClient = Object.assign({}, clientStore.currentEntity.client);
        currentClient.data.isFirstTimeLogin = false;
        currentClient.hasUpdatedWeekendsPayment = hasUpdatedWeekendsPayment;
        return clientStore.save(currentClient, false).then((result) => {
            if (!result.client || !result.client.id) {
                commonStore.addNotification(t('Something went wrong!'), null, 'error');
                this.setState({ isUpdating: false });
                return false;
            }
            ControlPanel.setOldClientData(result.client.data);
            commonStore.setAppLoaded(false).then((res) => {
                commonStore.addNotification(t('Saved'), null, 'success');
                this.setState({ isUpdating: false });
                this.props.router.navigate('/admin');
                return true;
            })

        }).catch(err => {
            console.error({ err });
            this.setState({ isUpdating: false });
        });
    }

    submitForm() {
        const { clientStore, t } = this.props;
        if (clientStore.currentEntity.client?.data?.extraPayments[0]?.amount !== ControlPanel.oldClientData.extraPayments[0]?.amount) {
            confirmAlert({
                title: this.props.t('Are you sure you want to update this') + '' + '?',
                message: t("By adjusting an additional percentage for weekends, the weekend extra percentage for all members will be updated"),
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                            this.saveClientsConfig(true);
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => {
                            clientStore.currentEntity.client.data.extraPayments[0].amount = ControlPanel.oldClientData.extraPayments[0]?.amount
                            this.saveClientsConfig();
                        },
                    },
                ],
            });
        }
        else {
            this.saveClientsConfig();
        }
    }

    completeSettings() {
        const { commonStore, t } = this.props;
        if (commonStore.config.client.data.isFirstTimeLogin) {
            return commonStore.addNotification(t('Please complete settings'), null, 'success');
        }
    }

    checkForUnsavedChanges(cb) {
        const { clientStore, t } = this.props;
        const newData = JSON.parse(JSON.stringify(clientStore.currentEntity.client.data))
        if (!_.isEqual(newData, ControlPanel.oldClientData)) {
            confirmAlert({
                title: t('Do you want to save the changes') + '' + '?',
                message: t("Changes are not saved yet and hence won't reflect, do you wish to save them?"),
                buttons: [
                    {
                        label: t('Save'),
                        onClick: () => {
                            this.submitForm();
                        },
                    },
                    {
                        label: t('Discard'),
                        onClick: () => {
                            this.props.clientStore.resetClientData(ControlPanel.oldClientData);
                            if (cb) cb();
                        },
                    },
                ],
            });
        } else {
            if (cb) cb();
        }
    }

    onCancel() {
        const cb = () => this.props.router.navigate('/admin');
        this.checkForUnsavedChanges(cb);
    }

    componentWillUnmount() {
        this.checkForUnsavedChanges(null);
    }

    render() {
        const { t, clientStore } = this.props;
        const { isLoad, isUpdating } = this.state;
        const allowTip = clientStore.currentEntity.client ? clientStore.currentEntity.client.allow_tip : '';
        return (
            <div className="control-panel">
                {isUpdating ?
                    <LoadingSpinner /> :
                    <>
                        <Tabs defaultActiveKey="dateTimeRules" id="MainTabContainer">
                            {/* <Tab eventKey="rolesAndPermission" title={t("Roles & Permissions")}>
                        {isLoad && <RolesAndPermission />}
                    </Tab> */}
                            <Tab eventKey="dateTimeRules" title={t('Regional Settings')}>
                                <h5 className='fw-600'>{t('Regional Settings')}</h5>
                                {isLoad && <DateTimeRules />}
                            </Tab>
                            <Tab eventKey="basicRules" title={t('Basic Rules')}>
                                <h5 className='fw-600'>{t('Basic Rules')}</h5>
                                {isLoad && <BasicRules />}
                            </Tab>
                            <Tab eventKey="extendedRules" title={t('Extended rules')}>
                                <h5 className='fw-600'>{t('Extended rules')}</h5>
                                {isLoad && <ExtendedRules />}
                            </Tab>
                            <Tab eventKey="projectRules" title={t('Project Rules')}>
                                <h5 className='fw-600'>{t('Project Rules')}</h5>
                                {isLoad && <ProjectRules />}
                            </Tab>
                            <Tab eventKey="Task Rules" title={t('Task Rules')}>
                                <h5 className='fw-600'>{t('Task Rules')}</h5>
                                {isLoad && <TaskRules />}
                            </Tab>
                            <Tab eventKey="deviationRules" title={t('Deviation Rules')}>
                                {isLoad && <DeviationRules />}
                            </Tab>
                            <Tab eventKey="vacationRules" title={t('Vacation Rules')}>
                                {isLoad && <VacationRules />}
                            </Tab>
                            <Tab eventKey="loginRules" title={t('Login Rules')}>
                                {isLoad && <LoginRules />}
                            </Tab>
                            <Tab eventKey="roleTypes" title={t('Titles')}>
                                {isLoad && <RoleTypes />}
                            </Tab>
                            <Tab eventKey="integrations" title={t('Integrations')}>
                                {isLoad && <Integrations />}
                            </Tab>
                            <Tab eventKey="extraPayments" title={`${t('Additional Payments')} (BETA)`}>
                                {isLoad && <ExtraPayments />}
                            </Tab>
                            {allowTip &&
                                <Tab eventKey="tipRules" title={t("Tip Rules")}>
                                    <h5 className='fw-600'>{t('Tip Rules')}</h5>
                                    {isLoad && allowTip && <TipRules />}
                                </Tab>
                            }
                        </Tabs>
                        <button className="btn btn-fill" onClick={() =>
                            this.setState({ isUpdating: true }, this.submitForm)}>
                            {t('Submit')}
                        </button>
                        <button className="btn" style={{ marginLeft: '2rem' }} onClick={() => this.onCancel()}>
                            {t('Cancel')}
                        </button>
                    </>
                }
            </div>
        );
    }
}

export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(ControlPanel))));
