import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from "moment";

// Components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from './../../hoc/withLocalization';
import EventItem from './EventItem';
import DnDSource from './DnDSource';
import DnDContext from './DnDContext';
import ResourceView from './ResourceView';
import HeaderView from './HeaderView';
import BodyView from './BodyView';
import ResourceEvents from './ResourceEvents';
import AddMorePopover from './AddMorePopover';
import ViewTypes from './ViewTypes';
import CellUnits from './CellUnits';
import SummaryPos from './SummaryPos';
import SchedulerData from './SchedulerData';

//utils
import scheduleConfig from './config';

// styles
import 'antd/lib/radio/style/index.css';
import 'antd/lib/popover/style/index.css';
import 'antd/lib/calendar/style/index.css';
import 'antd/lib/select/style/index.css';
import 'antd/lib/grid/style/index.css';
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("en-GB");
// Col, Row and Icon do not have their own less files for styling. They use
// rules declared in antd's global css. If these styles are imported directly
// from within antd, they'll include, for instance, reset rules. These will
// affect everything on the page and in essence would leak antd's global styles
// into all projects using this library. Instead of doing that, we are using
// a hack which allows us to wrap all antd styles to target specific root. In
// this case the root id will be "RBS-Scheduler-root". This way the reset styles
// won't be applied to elements declared outside of <Scheduler /> component.
//
// You can get more context for the issue by reading:
// https://github.com/ant-design/ant-design/issues/4331
// The solution is based on:
// https://github.com/ant-design/ant-design/issues/4331#issuecomment-391066131
//
// For development
// This fix is implemented with webpack's NormalModuleReplacementPlugin in
// webpack/webpack-dev.config.js.
//
// For library builds
// This fix is implemented by the build script in scripts/build.js
//
// The next components have their own specific stylesheets which we import
// separately here to avoid importing from files which have required the global
// antd styles.

class Scheduler extends Component {
    constructor(props) {
        super(props);
        const { schedulerData, currentView } = props;
        schedulerData._setDocumentWidth(document.documentElement.clientWidth);
        this.state = {
            contentScrollbarHeight: 17,
            contentScrollbarWidth: 17,
            resourceScrollbarHeight: 17,
            resourceScrollbarWidth: 17,
            scrollLeft: 0,
            scrollTop: 0,
            documentWidth: document.documentElement.clientWidth,
            documentHeight: document.documentElement.clientHeight,
            currentView: currentView
        };
        if (schedulerData.isSchedulerResponsive()) window.onresize = this.onWindowResize;
    }

    onWindowResize = (e) => {
        const { schedulerData } = this.props;
        schedulerData._setDocumentWidth(document.documentElement.clientWidth);
        this.setState({
            documentWidth: document.documentElement.clientWidth,
            documentHeight: document.documentElement.clientHeight,
        });
    };

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        onViewChange: PropTypes.func.isRequired,
        onSetAddMoreState: PropTypes.func,
        updateEventStart: PropTypes.func,
        updateEventEnd: PropTypes.func,
        moveEvent: PropTypes.func,
        movingEvent: PropTypes.func,
        newEvent: PropTypes.func,
        subtitleGetter: PropTypes.func,
        eventItemClick: PropTypes.func,
        viewEventClick: PropTypes.func,
        viewEventText: PropTypes.string,
        viewEvent2Click: PropTypes.func,
        viewEvent2Text: PropTypes.string,
        conflictOccurred: PropTypes.func,
        eventItemTemplateResolver: PropTypes.func,
        dndSources: PropTypes.array,
        slotClickedFunc: PropTypes.func,
        toggleExpandFunc: PropTypes.func,
        slotItemTemplateResolver: PropTypes.func,
        nonAgendaCellHeaderTemplateResolver: PropTypes.func,
        onScrollLeft: PropTypes.func,
        onScrollRight: PropTypes.func,
        onScrollTop: PropTypes.func,
        onScrollBottom: PropTypes.func,
    };

    getDNDContext = (props) => {
        let sources = [];
        sources.push(
            new DnDSource((props) => {
                return props.eventItem;
            }, EventItem)
        );
        const dndContext = new DnDContext(sources, ResourceEvents);
        return dndContext;
    }

    getContentHeight = (props) => {
        const { schedulerData, currentView } = props;
        let roleHeight = currentView ? 18 : 0;
        return schedulerData.getSchedulerContentDesiredHeight(roleHeight);
    }

    componentDidUpdate() {
        //don't see any use for the below//
        const { schedulerData } = this.props;
        const { localeMoment, behaviors } = schedulerData;
        if (schedulerData.getScrollToSpecialMoment() && !!behaviors.getScrollSpecialMomentFunc) {
            if (!!this.schedulerContent && this.schedulerContent.scrollWidth > this.schedulerContent.clientWidth) {
                const start = localeMoment(schedulerData.startDate).startOf('day');
                const end = localeMoment(schedulerData.endDate).endOf('day');
                const specialMoment = behaviors.getScrollSpecialMomentFunc(schedulerData, start, end);
                if (specialMoment >= start && specialMoment <= end) {
                    let index = 0;
                    schedulerData.headers.forEach((item) => {
                        const header = localeMoment(item.time);
                        if (specialMoment >= header) index++;
                    });
                    this.schedulerContent.scrollLeft = (index - 1) * schedulerData.getContentCellWidth();
                    schedulerData.setScrollToSpecialMoment(false);
                }
            }
        }
    }

    onViewChange = (e) => {
        const { onViewChange, schedulerData } = this.props;
        const viewType = e.value;
        const isEventPerspective = e.value === 1;
        onViewChange(schedulerData, { viewType, isEventPerspective });
    };

    render() {
        const { contentScrollbarHeight, contentScrollbarWidth, resourceScrollbarHeight, resourceScrollbarWidth } = this.state;
        const { schedulerData, currentView, t, commonStore, userStore, scheduleStore } = this.props;
        const { config } = schedulerData;
        let { renderData } = schedulerData;
        const isMember = userStore.currentUser.user_type === 'member';
        const width = schedulerData.getSchedulerWidth();
        const schedulerWidth = schedulerData.getContentTableWidth() - 1;
        const resourceTableWidth = schedulerData.getResourceTableWidth();
        const contentHeight = this.getContentHeight(this.props) + 80;
        const dndContext = this.getDNDContext(this.props);
        const DndResourceEvents = dndContext.getDropTarget();
        const eventDndSource = dndContext.getDndSource();
        const resourcePaddingBottom = resourceScrollbarHeight === 0 ? contentScrollbarHeight : 0;
        const contentPaddingBottom = contentScrollbarHeight === 0 ? resourceScrollbarHeight : 0;
        const { filters } = scheduleStore;
        let filteredRenderData = [...renderData];
        if (!!currentView && !!filters.resourceFilterValue && !Object.is(filters.resourceFilterValue.value,null)) {
            let _filteredRenderData = [];
            filteredRenderData.forEach(r => {
                if (r.slotId === 0 || r.slotId === 'pending') _filteredRenderData.push(r);
                else if (filters.resourceFilterValue.value
                    && schedulerData.events.some(e => e[currentView === 2 ? 'userGroup' : 'employeeType'] === r.slotId))
                    _filteredRenderData.push(r);
                else if (!filters.resourceFilterValue.value
                    && schedulerData.events.every(e => e[currentView === 2 ? 'userGroup' : 'employeeType'] !== r.slotId))
                    _filteredRenderData.push(r);
            })
            filteredRenderData = _filteredRenderData;
        }
        const resourceEventsList = filteredRenderData.map((item) => {
            return (
                <Fragment key={item.slotId}>
                    <DndResourceEvents
                        {...this.props}
                        resourceEvents={item}
                        dndSource={eventDndSource}
                    />
                </Fragment>
            );
        });

        let schedulerContentStyle = {
            overflowX: 'auto',
            overflowY: 'hidden',
            margin: '0px',
            position: 'relative',
            paddingBottom: contentPaddingBottom,
        };
        let resourceContentStyle = {
            overflowX: 'auto',
            overflowY: 'hidden',
            width: resourceTableWidth + resourceScrollbarWidth - 2,
            margin: `0px -${contentScrollbarWidth}px 0px 0px`,
        };
        if (config.schedulerMaxHeight > 0) {
            schedulerContentStyle = {
                ...schedulerContentStyle,
            };
            resourceContentStyle = {
                ...resourceContentStyle,
            };
        }

        const dateTimeRules = commonStore.config.client && commonStore.config.client.data &&
            commonStore.config.client.data.dateTimeRules ?
            { ...commonStore.config.client.data.dateTimeRules }
            : { short_date_format: "DD.MM.YYYY", time_format: "hh:mm" };
        const isScheduleShiftsCalculationsEnabled = commonStore?.config?.client?.data?.basicRules.enableScheduleShiftsCalculations;
        let tbodyContent = (
            <tr>
                <td style={{ width: resourceTableWidth, verticalAlign: 'top' }}>
                    <div className="resource-view" style={{ borderRight: '2px solid #e8e8e8' }}>
                        <div
                            style={{
                                overflow: 'hidden',
                                borderBottom: '1px solid #e9e9e9',
                                height: schedulerData.getTableHeaderHeight(currentView && isScheduleShiftsCalculationsEnabled),
                            }}
                        >
                            <div
                                style={{
                                    overflowX: 'scroll',
                                    overflowY: 'hidden',
                                    margin: `0px 0px -${contentScrollbarHeight}px`,
                                }}
                            >
                                <table className="resource-table">
                                    <thead >
                                        <tr style={{ height: schedulerData.getTableHeaderHeight(currentView && isScheduleShiftsCalculationsEnabled) }}>
                                            <th className="header3-text schedule-resource-selection text-center" >
                                                <span style={{ fontSize: '25px', fontWeight: '600', color: '#2550AC' }}>
                                                    {t(scheduleConfig.scheduleListingViews.find(e => e.value === currentView)?.label)}
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <div
                            style={resourceContentStyle}
                        >
                            <ResourceView
                                {...this.props}
                                config={commonStore.config}
                                filteredRenderData={filteredRenderData}
                                contentScrollbarHeight={resourcePaddingBottom}
                                isMember={isMember}
                                dateTimeRules={dateTimeRules}
                            />
                        </div>
                    </div>
                </td>

                <td valign="top">
                    <div className="scheduler-view">
                        <div
                            style={{
                                overflow: 'hidden',
                                borderBottom: '1px solid #e9e9e9',
                                height: schedulerData.getTableHeaderHeight(currentView && isScheduleShiftsCalculationsEnabled),
                                width: schedulerWidth
                            }}
                        >
                            <div
                                style={{
                                    overflow: 'unset',
                                    margin: `0px 0px -${contentScrollbarHeight}px`,
                                }}
                            >
                                <div
                                    style={{
                                        paddingRight: `${contentScrollbarWidth}px`,
                                        width: schedulerWidth + contentScrollbarWidth,
                                    }}
                                >
                                    <table className="scheduler-bg-table">
                                        <HeaderView {...this.props} />
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div
                            style={schedulerContentStyle}
                        >
                            <div style={{ width: schedulerWidth, height: contentHeight }}>
                                <div className="scheduler-content">
                                    <table className="scheduler-content-table">
                                        <tbody >{resourceEventsList}</tbody>
                                    </table>
                                </div>
                                <div className="scheduler-bg">
                                    <table
                                        className="scheduler-bg-table"
                                        style={{ width: schedulerWidth }}
                                    >
                                        <BodyView
                                            {...this.props}
                                            filteredRenderData={filteredRenderData}
                                            t={t}
                                        />
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        );
        return (
            <table id="RBS-Scheduler-root" className="scheduler" style={{ width: `${width}px` }}>
                <tbody>{tbodyContent}</tbody>
            </table>
        );
    }
}
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export { SchedulerData, ViewTypes, CellUnits, SummaryPos, DnDSource, DnDContext, AddMorePopover };
export default inject('userStore', 'commonStore', 'scheduleStore', 'projectStore')(applicationRouter(withLocalization(observer(Scheduler))));