import { observable, action, makeObservable } from 'mobx';
import _ from 'lodash';

import agent from '../agent';

let addressThrottleTimer = null;

class TaskTemplateStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            loadingLookup: observable,
            lastListLoadTime: observable,
            gpsNowLoading: observable,
            resetLastListLoadTime: action,
            filters: observable,
            appliedFilters: observable,
            setFilter: action,
            onFilter: action,
            loadList: action,
            returnDefaultNew: action,
            load: action,
            save: action,
            remove: action,
            performRecalcGpsForTask: action,
            recalcGpsForTask: action,
            assignTemplates: action,
            loadTempletView: action
        });
    }

    currentList = {};

    currentEntity = {};

    updatingErrors = null;

    updating = false;

    loading = false;

    requestParams = null;

    deleteSuccess = false;

    loadingLookup = false;

    lastListLoadTime = null;

    gpsNowLoading = false;

    filters = {
        name: '',
        project: ''
    };

    appliedFilters = {
        name: '',
        project: ''
    };

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }
    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    returnDefaultNew(params) {
        this.currentEntity = {
            taskTemplate: {
                name: undefined,
                data: {},
                description: undefined,
                est_hours: 40,
                project_id: params.project_id
            },
        };
        this.loading = false;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    loadList(params) {
        return agent.TaskTemplates.list(params)
            .then(
                action(list => {
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    load(id, project_id) {
        this.loading = true;
        return agent.TaskTemplates.load(id)
            .then(
                action(response => {
                    response.taskTemplate.project_id = project_id
                    this.currentEntity = response;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    loadTempletView(id, project_id) {
        this.loading = true;
        return agent.TaskTemplates.loadView(id, { project: project_id })
            .then(
                action(response => {
                    response.taskTemplate.project_id = project_id
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    save(values) {
        this.updating = true;
        return agent.TaskTemplates.save(values)
            .then(
                action(result => {
                    this.currentEntity = result;
                    return result;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    assignTemplates(values) {
        this.updating = true;
        return agent.TaskTemplates.assign(values)
            .then(
                action(result => {
                    return result;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async remove(task_template_id) {
        await agent.TaskTemplates.remove(task_template_id);
        this.deleteSuccess = true;
        return true;
    }

    async performRecalcGpsForTask(newAddress) {
        this.gpsNowLoading = true;
        this.currentEntity.taskTemplate.data.addressCache = newAddress;
        let taskTemplate = _.cloneDeep(this.currentEntity.taskTemplate);
        try {
            const data = await agent.Tasks.gpsAddress(newAddress);
            taskTemplate.gps_data = data.result;
            taskTemplate.data.addressCache = newAddress;
            this.currentEntity = { taskTemplate: taskTemplate };
            this.gpsNowLoading = false;
        } catch (e) {
            this.gpsNowLoading = false;
            throw e;
        }
    }

    async recalcGpsForTask(changes) {
        const entityToAddressString = e => {
            return `${e.address}, ${e.post_place}, ${e.post_number}`;
        };
        if (!changes.address || !changes.post_place) {
            return changes;
        }
        const newAddress = entityToAddressString(changes);
        if (newAddress === this.currentEntity.taskTemplate.data.addressCache) return changes;
        addressThrottleTimer = new Date().getTime();
        setTimeout(() => {
            // throttle
            if (new Date().getTime() - addressThrottleTimer < 1000) {
                return;
            }
            if (this.currentEntity.taskTemplate.data.addressCache === newAddress) {
                return;
            }
            this.performRecalcGpsForTask(newAddress);
        }, 1000);
        return changes;
    }
}

const _TaskTemplateStore = new TaskTemplateStore();
export default _TaskTemplateStore;
