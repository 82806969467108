import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

// Components
import Button from '../../../components/CustomButton/CustomButton.jsx';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

// import ElementList1 from './ElementList1';

import ElementList from './Element/ElementList';

import ChecklistTemplateList from './CheckListTemplate/ChecklistTemplateList';
import ChecklistTable from './ChecklistTable';


import '../../../styles/css/styles.css';

class Checklist extends Component {
    state = {
        checklist_data: [],
        element_data: [],
        showAddElement: false,
        elementarray: [],
        showaddelement: false,
        showChecklistTemplate: false,
        checklistTemplateArray: [],
        showChecklistAssign: false,
        addChecklist: false,
        add: false,
        currentChecklistId: 0,
        showChecklistTodo: false,
        checklistChanged: false,
        currenttab: 1,
    };

    componentDidMount() {

    }

    renderAddElementModal = () => {
        this.setState({
            showAddElement: true,
            showChecklistAssign: true,
        });
    };

    renderChecklistTodoModal = () => {
        this.setState({
            showChecklistTodo: true,
        });
    };

    viewChecklistTemplates = () => {
        this.setState({
            addChecklist: true,
        });
        this.renderChecklistTemplateModal();
    };

    // onAddelements = () => {
    //     this.loadelements();
    //     const { history } = this.props;
    //     this.renderAddElementModal();
    // };

    // loadelements = async () => {
    //     let data = {
    //         id: this.props.userStore.currentUser.client_id,
    //     };
    //     await this.props.checklistStore.listelementbyclientid().then((list) => {
    //         this.setState({ checklistTemplateArray: list.elements });
    //     });
    // };

    loadChecklistTemplates = async () => {
        await this.props.checklistStore.getChecklistTemplateByClientId().then((list) => {
            this.setState({ elementarray: list.rows });
        });
    };
    handleClosesAddElementModal = () => {
        this.setState({ showAddElement: false });
        // this.loadelements();
    };
    onAddelements = () => {
        // this.loadelements();

        this.renderAddElementModal();
    };

    handleClosesChecklisAssignModal = () => {
        this.setState({ showChecklistAssign: false, add: false });
        this.props.checklistStore.returnDefaultNew();
    };

    handleClosesChecklisTodoModal = () => {
        this.setState({ showChecklistTodo: false });
    };

    handleTableButtonAction = (id, type) => {
        // this.props.onTableAction(id, type);
        if (type === 'edit') {
            this.setState({ currentChecklistId: id, add: false });
            this.renderChecklistAssignModal();
        } else if (type === 'todo') {
            this.setState({ currentChecklistId: id, add: false });
            this.renderChecklistTodoModal();
        }
    };

    // onTableAction = (id, type) => {
    //     const { history } = this.props;
    //     if (type === 'remove') {
    //         return this.props.handleDelete(id, (id) => {
    //             return this.props.absenceStore.remove(id).then((res) => {
    //                 this.props.absenceStore.resetLastListLoadTime();
    //                 return res;
    //             });
    //         });
    //     }
    //     history.push(`/admin/absences/${id}`);
    // };

    renderTableActionButtons = (id, full) => {
        return (
            <div className="actions-center">
                <Button onClick={() => this.handleTableButtonAction(id, 'todo')} simple icon>
                    <i className="fa fa-thumbs-up" />
                </Button>
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} simple icon>
                    <i className="fa fa-edit" />
                </Button>
                {/* {(user_type == 'member' || (user_type == 'member' && full.assignee_id == userId) || allow) &&
                    !full.is_default_for_project && (
                        <Button onClick={() => this.handleTableButtonAction(id, 'remove')} simple icon>
                            <i className="fa fa-times" />
                        </Button>
                    )}
               */}
            </div>
        );
    };

    applyForm = (params = {}) => {
        const { currentChecklist: checklist } = this.props.checklistStore;
        const { t, commonStore, checklistStore } = this.props;
        let hydrated = {
            user_id: checklist.user_id,
            project_id: checklist.project_id,
            checklist_templates_id: checklist.checklist_templates_id,
            //to_project: checklist.to_project,
            isSendEmail : checklist.isSendEmail,
        };
        if (checklist.id && checklist.id > 0) {
            hydrated['id'] = checklist.id;
        }
        //if (hydrated.checklist_templates_id && (hydrated.user_id || hydrated.to_project )) {
        if (hydrated.checklist_templates_id) {
            checklistStore
                .assignChecklist(hydrated)
                .then((res) => {
                    this.setState({ checklistChanged: true });
                    this.props.checklistStore.resetLastListLoadTime();
                    commonStore.addNotification(t('Checklist assign sucessfully'), null, 'success');
                    this.handleClosesChecklisAssignModal();
                })
                .catch((err) => {
                    commonStore.addNotification(err.message || t('Error'), null, 'error');
                });
        } else {
            commonStore.addNotification(t('Error'), null, 'error');
        }
    };

    Opentab = (tabid) => {
        this.setState({ currenttab: tabid });
    };

    getComponent = (tab) => {

        switch (tab) {
            case 1:
                return <ChecklistTable />;
            case 2:
                return <ChecklistTemplateList />;
            case 3:
                return <ElementList />;
            default:
                return <ChecklistTable />;
        }
    };
    render() {
        const { t } = this.props;
        return (
            <>
                <div className='tabContainer'>
                    <button className={(this.state.currenttab === 1 ? "tablink tablink__active-left" : "tablink tablink__left")} onClick={() => this.Opentab(1)}>
                        {t('My Checklist')}
                    </button>
                    <button className={(this.state.currenttab === 2 ? "tablink tablink__active-center" : "tablink tablink__center")} onClick={() => this.Opentab(2)}>
                        {t('Create checklist')}
                    </button>
                    <button className={(this.state.currenttab === 3 ? "tablink tablink__active-right" : "tablink tablink__right")} onClick={() => this.Opentab(3)}>
                        {t('Elements')}
                    </button>
                </div>
                <div className="page"> {this.getComponent(this.state.currenttab)}</div>
            </>
        );
    }
}
export default inject('checklistStore', 'authStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(withRemoveDialog(observer(Checklist)))));
