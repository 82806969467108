import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

//components
import withPermissions from '~/hoc/withPermissions';
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//assets
import CloseIcon from "~/assets/img/modal-close-icon.svg";
import ActivateUser from '../../../assets/img/ActivateUser.svg'
import LoadingSpinner from '../../../elements/LoadingSpinner';

class ProjectImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showImportModal: true,
            projectToSync: [],
            projectAddIds: [],
            loading: false,
            showResponseText: ''
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const { projectStore } = this.props
        this.setState({ loading: true })
        const getProjectList = await projectStore.getAllProjectFromIntegration()
        this.setState({ projectToSync: getProjectList, loading: false })
    }

    importProjectFromIntegration = async () => {
        const { projectStore, commonStore, t } = this.props;
        const { projectAddIds } = this.state;
        const str = t(`Do You want to Import selected Project into TimeAndControl`);
        confirmAlert({
            title: this.props.t('Confirm to Import'),
            message: str,
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        this.setState({ loading: true })
                        return projectStore.saveProjectFromIntegration({projects: projectAddIds}).then((res2) => {
                            commonStore.addNotification(`${t(res2.message)} ${commonStore.config?.integration_details?.product}`, null, 'success');
                            projectStore.resetLastListLoadTime();
                            this.setState({ loading: false, showResponseText: t("Please assign the projects to members so that members can start recording work hours, etc., and don't forget to include the project address by clicking on the 'Edit' button")})
                            return res2;
                        }).catch(error => {
                            this.setState({ loading: false })
                        });;
                    },
                },
                {
                    label: this.props.t('No'),
                    onClick: () => { },
                },
            ],
        })
    }

    handleCheckbox = (ele, e) => {
        if (e) {
            this.setState({ projectAddIds: [ele, ...this.state.projectAddIds] })
        }
        else {
            const updateIds = this.state.projectAddIds.filter(item => item.project.id !== ele.project.id)
            this.setState({ projectAddIds: updateIds })
        }
    }

    render() {
        const { t, handleImportModal } = this.props;
        const { loading, showImportModal, showResponseText } = this.state
        return (
            <Modal
                size="lg"
                className="modal fade"
                show={showImportModal}
                onHide={() => handleImportModal(!this.state.showImportModal)}
            >
                <Modal.Header>
                    <Modal.Title>{t('Import Projects from')} {this.props.commonStore.config?.integration_details?.product}</Modal.Title>
                    <div className='modal-header__revised__closebtn' onClick={() => handleImportModal(!this.state.showImportModal)}>
                        <img
                            src={CloseIcon}
                            className="cursor-pointer"
                            alt='close_button'
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className='mb-2'>
                    {loading ?
                        <LoadingSpinner />
                        :
                        <Fragment>
                            {showResponseText === '' ?
                                <>
                                    <div>
                                        {t('Please choose the project you wish to import')}
                                    </div>
                                    <div>
                                        <Table className='w-100 '>
                                            <thead>
                                                <tr className='w-100'>
                                                    <th width={'50%'}>{t('Project')}</th>
                                                    <th width={'50%'}>{t('Is Synced')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.projectToSync.map((ele, idx) => (
                                                    <tr key={idx} className='text-capitalize mt-1'>
                                                        <td width={'50%'} className=''><div className=''>{ele.project.name}</div></td>
                                                        <td width={'50%'} className='timelog_array_details'>
                                                            {ele.isSynced ?
                                                                <img src={ActivateUser} alt='tick' title='Already Synced' />
                                                                :
                                                                <Checkbox
                                                                    isChecked={ele.isSynced || ele?.nowSynced}
                                                                    onChange={checked => this.handleCheckbox(ele, checked)}
                                                                    disabled={ele.isSynced}
                                                                    wrapperClassName={'bulk-shift-chekbox-style'}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 1024 1024"><path fill="#2550AC" d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896zm-55.808 536.384l-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"></path></svg>
                                        <div className='w-75'>{showResponseText}</div>
                                    </div>
                                </>
                            }
                        </Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {showResponseText === '' ?
                        <>
                            <Button fill wd
                                onClick={this.importProjectFromIntegration}
                                disabled={!this.state.projectAddIds.length}
                            >
                                {t('Import Selected Projects')}
                            </Button>
                            <Button wd
                                onClick={() => handleImportModal(!this.state.showImportModal)}
                            >
                                {t('Cancel')}
                            </Button>
                        </>
                        :
                        <>
                            <Button wd
                                onClick={() => handleImportModal(!this.state.showImportModal)}
                            >
                                {t('OK')}
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}
export default inject(
    'projectStore',
    'userStore',
    'commonStore',
    'clientStore'
)(applicationRouter(withPermissions(withLocalization(observer(ProjectImport)))));
