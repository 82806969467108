import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find, cloneDeep, sortBy } from 'lodash';
import { v4 as uuid } from 'uuid';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';

//elements
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';

//assets
import Delete from '../../../assets/img/deleting.svg';


class ManagesMembersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            members: null,
            selectedUsers: null,
            editingRowId: null,
            usersToAdd: null,
        };
        this.loadData = this.loadData.bind(this);
        this.getSortedMembers = this.getSortedMembers.bind(this);
        this.onSave = this.onSave.bind(this);
        this.getUsersToAdd = this.getUsersToAdd.bind(this);
        this.assignMembersByProject = this.assignMembersByProject.bind(this);
        this.handleSelectChanged = this.handleSelectChanged.bind(this);
    }

    async loadData() {
        const { id, userStore } = this.props;
        if (!id) return;
        const user = await userStore.load(id);
        const users = await userStore.loadLookup('members', '');
        const members = this.getSortedMembers(cloneDeep(user.user.ManagesUsers), users);
        const membersIds = members.map(({ id }) => id);
        const selectedUsers = [...new Set(membersIds)];
        const usersToAdd = this.getUsersToAdd(user.user.ManagesUsers, users);
        const userOptions = usersToAdd.map((r) => {
            return { value: r.id, label: r.fullname };
        });

        this.setState({
            users,
            selectedUsers,
            members,
            usersToAdd,
            userOptions,
        });
    }

    getSortedMembers(members, users) {
        members.forEach((member) => {
            const user = find(users, (r) => r.id === member.user_id);
            member.fullname = user ? user.fullname : '???';
        });
        members = sortBy(members, (r) => r.fullname.toUpperCase());
        return members;
    }

    componentDidUpdate(previousProps) {
        const { id } = this.props;
        if (id !== previousProps.id) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async onSave() {
        const { userStore, id, t, commonStore, afterSave } = this.props;
        await userStore.save({
            id,
            data: {},
            ManagesUsers: this.state.selectedUsers.map((id) => ({ id })),
        });
        commonStore.addNotification(t('Saved'), null, 'success');
        afterSave();
    }

    getUsersToAdd(members, users) {
        const toAdd = [];
        const memberIds = [];
        members.forEach((member) => memberIds.push(member.user_id));
        users.forEach((user) => {
            if (memberIds.indexOf(user.id) >= 0) return;
            toAdd.push(user);
        });
        return toAdd;
    }

    async assignMembersByProject(id) {
        let userOptionsOld = this.state.userOptions;
        const { projectStore } = this.props;
        await projectStore.getProjectMembersIds(id).then((data) => {
            let projectMemberIds = data.membersArray || [];
            this.setState({
                selectedUsers: projectMemberIds,
                members: userOptionsOld
                    .filter((option) => projectMemberIds.indexOf(option.value) !== -1)
                    .map((option) => ({ id: option.value, label: option.label })),
            });
        });
    }

    handleSelectChanged = (selected) => {
        const { userOptions } = this.state;
        const updatedMembers = userOptions
            .filter((option) => selected.indexOf(option.value) !== -1)
            .map((option) => ({ id: option.value, label: option.label }));
        this.setState({
            selectedUsers: selected,
            members: updatedMembers,
        });
    };

    render() {
        const { users, members, selectedUsers, userOptions } = this.state;
        const { id, t } = this.props;
        if (!users || !members || !id) {
            return <LoadingSpinner />;
        }

        return (
            <>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="form-group field field-string">
                            <label className="control-label">{t('Select Members')}</label>
                            <CustomMultiSelect
                                options={userOptions}
                                value={selectedUsers || []}
                                onChange={this.handleSelectChanged}
                            />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group field field-string">
                            <label className="control-label">{t('Assigned members of projects')}</label>
                            <ProjectsWidget
                                value={''}
                                placeholder={'Assigned members of projects'}
                                onChange={(id) => this.assignMembersByProject(id)}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 text-end mt-3">
                        <Button primary fill onClick={() => this.onSave()}>
                            {t('Save')}
                        </Button>
                    </div>
                </div>
                <div style={{ overflowY: 'scroll', maxHeight: '500px' }}>
                    <table className="table table-hover project-members-form">
                        <thead>
                            <tr className="table-container-header">
                                <th className='text-start'>{t('Member')}</th>
                                <th className='text-right'>{t('Action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member) => (
                                <tr key={uuid()}>
                                    <td className='text-start'>{member.label ? member.label : `${member.first_name} ${member.last_name}`}</td>
                                    <td className="text-right">
                                        <Button
                                            icon_sm_delete
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    selectedUsers: state.selectedUsers.filter((id) => id != member.id),
                                                    members: state.members.filter((m) => m.id != member.id),
                                                }));
                                            }}
                                        >
                                            <img src={Delete} alt="delete button" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default inject(
    'projectStore',
    'commonStore',
    'userStore'
)(applicationRouter(withLocalization(observer(ManagesMembersForm))));
