import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

//emlments
import CustomMultiSelect from '../../../../elements/CustomMultiSelect';

//styles
import '../../../../styles/css/styles.css';

//this component displays all the members with checkbox// 
//and the ones in the current template are checked//

class DocumentMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],//for selected members
            options: [],//for members list      
        };
        this.loadData = this.loadData.bind(this);
        this.handleSelectedOptions = this.handleSelectedOptions.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }


    //loads memberList and assigns checked members to document template//
    async loadData() {
        const { userStore, value } = this.props;
        await userStore.loadLookup('notadmin', '').then((data) => {
            let multiSelectOptions = [];
            data.map(item => {
                multiSelectOptions.push({
                    label: item.fullname,
                    value: item.id
                })
            })
            this.setState({ options: multiSelectOptions, selected: value });
        });

    }

    //handlesSelectedOptions
    handleSelectedOptions = (selected) => {
        this.setState({ selected })
        this.props.onChange(selected)
    }

    render() {
        //renders members with checkbox and checked members//
        const { selected } = this.state;
        // const { t } = this.props;
        return (
            <CustomMultiSelect
                options={this.state.options}
                value={selected}
                onChange={selected => this.handleSelectedOptions(selected)}
            />
        )
    }
}

export default inject('userStore', 'commonStore')(applicationRouter(withLocalization(withRemoveDialog(observer(DocumentMembers)))));