export const countryOptions = [
    { value: '', label: 'All' },
    { value: 'United States', label: 'United States' },
    { value: 'Russia', label: 'Russia' },
    { value: 'Greek', label: 'Greek' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Lithuania', label: 'Lithuania' },
];
export const moduleOptions = [
    { value: 'project', label: 'project' },
    { value: 'user', label: 'user' },
    { value: 'document', label: 'document' }
];

export const optionStatus = [
    { code: 'draft', name: 'Draft', title: 'Draft' },
    { code: 'pending', name: 'Pending', title: 'Pending' },
    { code: 'completed', name: 'Completed', title: 'Completed' },
    { code: 'expired', name: 'Expired', title: 'Expired' }
]

export const extraPaymentWageTypes = [
    { value: "amount", label: "Hourly amount" },
    { value: "percentage", label: "Hourly %" },
    { value: "fixed_hourly_amount", label: "Fixed amount for shift" },
]

export const socialLinks = {
    "linkedin": "https://www.linkedin.com/company/time-and-control/",
    "instagram": "https://instagram.com/timeandcontrol?igshid=YmMyMTA2M2Y="
}

export const ClockCheckIcon = ({ width = 32, fillColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24"><path fill={fillColor} d="M17 3.34a10 10 0 1 1-14.995 8.984L2 12l.005-.324A10 10 0 0 1 17 3.34m-1.293 5.953a1 1 0 0 0-1.32-.083l-.094.083L11 12.585l-1.293-1.292l-.094-.083a1 1 0 0 0-1.403 1.403l.083.094l2 2l.094.083a1 1 0 0 0 1.226 0l.094-.083l4-4l.083-.094a1 1 0 0 0-.083-1.32" /></svg>

export const ClockSVG = ({ width = 32, fillColor = "none", strokeColor = "currentColor" } = {}) => <svg width={width} viewBox="0 0 14 14" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0C3.14015 0 0 3.14015 0 7C0 10.8598 3.14015 14 7 14C10.8598 14 14 10.8598 14 7C14 3.14015 10.8598 0 7 0ZM7 12.9231C3.734 12.9231 1.07692 10.266 1.07692 7C1.07692 3.734 3.734 1.07692 7 1.07692C10.266 1.07692 12.9231 3.734 12.9231 7C12.9231 10.266 10.266 12.9231 7 12.9231ZM9.41785 3.87215L7.53846 5.71692V2.20523C7.53846 1.90785 7.29738 1.66677 7 1.66677C6.70262 1.66677 6.46154 1.90785 6.46154 2.20523V7C6.46154 7.21692 6.59169 7.41262 6.79169 7.49662C6.85908 7.52492 6.92969 7.53846 7 7.53846C7.13846 7.53846 7.27462 7.48508 7.37738 7.38431L10.1723 4.64077C10.3845 4.43246 10.3875 4.09154 10.1794 3.87923C9.97108 3.66692 9.63015 3.66369 9.41785 3.87215Z" fill={strokeColor} />
</svg>

export const ClockCheckSVG = ({ width = 32, fillColor = "none", strokeColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24"><g fill={fillColor} stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M20.942 13.021a9 9 0 1 0-9.407 7.967" /><path d="M12 7v5l3 3m0 4l2 2l4-4" /></g></svg>

export const ClockExclamationSVG = ({ width = 32, fillColor = "none", strokeColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24"><g fill={fillColor} stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M20.986 12.502a9 9 0 1 0-5.973 7.98" /><path d="M12 7v5l3 3m4 1v3m0 3v.01" /></g></svg>

export const CalendarClockSVG = ({ width = 32, fillColor = "none", strokeColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24"><g fill={fillColor} stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M10.5 21H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3m-4-7v4M8 3v4m-4 4h10" /><path d="M14 18a4 4 0 1 0 8 0a4 4 0 1 0-8 0" /><path d="M18 16.5V18l.5.5" /></g></svg>

export const ClockAtLocationSVG = ({ width = 32, fillColor = "none", strokeColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24"><g fill={fillColor} stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M20.971 11.278a9 9 0 1 0-8.313 9.698" /><path d="M12 7v5l1.5 1.5m7.621 6.621a3 3 0 1 0-4.242 0Q17.506 20.749 19 22q1.577-1.335 2.121-1.879M19 18v.01" /></g></svg>

export const MultipleShiftsSGV = ({ width = 32, fillColor = "none", strokeColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24">
    <g fill={fillColor} stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M7 5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2z" />
        <path d="M17 17v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h2" />
    </g>
</svg>

export const NotificationSGV = ({ width = 32, fillColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24"><path fill={fillColor} d="M17.451 2.344a1 1 0 0 1 1.41-.099a12.05 12.05 0 0 1 3.048 4.064a1 1 0 1 1-1.818.836a10.05 10.05 0 0 0-2.54-3.39a1 1 0 0 1-.1-1.41zM5.136 2.245a1 1 0 0 1 1.312 1.51a10.05 10.05 0 0 0-2.54 3.39a1 1 0 1 1-1.817-.835a12.05 12.05 0 0 1 3.045-4.065M14.235 19c.865 0 1.322 1.024.745 1.668A4 4 0 0 1 12 22a4 4 0 0 1-2.98-1.332c-.552-.616-.158-1.579.634-1.661l.11-.006zM12 2c1.358 0 2.506.903 2.875 2.141l.046.171l.008.043a8.01 8.01 0 0 1 4.024 6.069l.028.287L19 11v2.931l.021.136a3 3 0 0 0 1.143 1.847l.167.117l.162.099c.86.487.56 1.766-.377 1.864L20 18H4c-1.028 0-1.387-1.364-.493-1.87a3 3 0 0 0 1.472-2.063L5 13.924l.001-2.97A8 8 0 0 1 8.822 4.5l.248-.146l.01-.043a3 3 0 0 1 2.562-2.29l.182-.017z" /></svg>
export const SyncSGV = ({ width = 32, fillColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24"><path fill={fillColor} d="M5.3 18.025q-1.075-1.2-1.687-2.75T3 12q0-3.75 2.625-6.375T12 3V1l5 3.75l-5 3.75v-2q-2.275 0-3.887 1.613T6.5 12q0 1.15.438 2.15t1.187 1.75zM12 23l-5-3.75l5-3.75v2q2.275 0 3.888-1.612T17.5 12q0-1.15-.437-2.15T15.875 8.1L18.7 5.975q1.075 1.2 1.688 2.75T21 12q0 3.75-2.625 6.375T12 21z" /></svg>
export const CircleExclamationSGV = ({ width = 32, fillColor = "currentColor" } = {}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 36 36"><path fill={fillColor} d="M18 6a12 12 0 1 0 12 12A12 12 0 0 0 18 6m-1.49 6a1.49 1.49 0 0 1 3 0v6.89a1.49 1.49 0 1 1-3 0ZM18 25.5a1.72 1.72 0 1 1 1.72-1.72A1.72 1.72 0 0 1 18 25.5" className="clr-i-solid clr-i-solid-path-1" /><path fill="none" d="M0 0h36v36H0z" /></svg>

export const defaultDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];