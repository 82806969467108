import React, { Component } from 'react';
import _ from 'lodash';
import { Table } from 'react-bootstrap';

//components
import { AttachmentsWidget } from '../GenericForm/Widgets/AttachmentsWidget';
import Signature from '../Signature/Signature';
import withLocalization from '../../hoc/withLocalization';

//services
import config from '../../library/config';

//elements
import Button from '../CustomButton/CustomButton.jsx';
import { NumberInput, Textarea } from '../../elements/Input';
import Select from '~/elements/Select';

//assets
import trashIcon from '../../assets/img/trash.svg';
import plusIcon from '../../assets/img/plusIcon.svg'


@withLocalization
class TimelogArray extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrayOfItems: [],
            entity: {
                type: '',
                value: '',
                signature: null,
                attachIds: '',
                comment: '',
            },
            wrongSubmit: false,
        };
    }

    componentDidMount() {
        const { initData } = this.props;
        this.setState({
            arrayOfItems: initData || [],
        });
    }

    addItem = e => {
        e.preventDefault();
        const state = Object.assign({}, this.state);
        const { arrayOfItems, entity } = state;

        if (entity.type && entity.value && entity.comment) {
            arrayOfItems.push(entity);
            state.entity = {
                type: '',
                value: '',
                signature: null,
                attachIds: '',
                comment: '',
            };
            state.wrongSubmit = false;
            this.setState(state);
            this.props.onSave(arrayOfItems);
        } else {
            state.wrongSubmit = true;
            this.setState(state);
        }
    };

    removeItem = index => {
        const state = Object.assign({}, this.state);
        state.arrayOfItems = state.arrayOfItems.filter((item, i) => i !== index);
        this.setState(state);
        this.props.onSave(state.arrayOfItems);
    };

    handleSave = () => {
        this.props.onSave(this.state.arrayOfItems);
    };

    handleChangeEntity = (name, value) => {
        const state = Object.assign({}, this.state);
        state.entity[name] = value;
        this.setState(state);
    };

    getOptions = mode => {
        return this.props.options.map(o => {
            return { value: o.code, label: o.name };
        });
    };

    getUrl = file => {
        return `${config.UPLOADS_API_ENDPOINT}/${file}`;
    };

    render() {
        const { existingAttachment = [], t, mode } = this.props;
        const { entity, arrayOfItems } = this.state;
        const isExpence = mode == 'expenses';
        const options = this.getOptions(mode);

        const currentSelect = _.find(options, o => o.value == entity.type);

        return (
            <form className="timelog-array" onSubmit={this.addItem}>
                <div className="timelog-array__left">
                    <div className="timelog-array__left-inputs">
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <Select
                                    label={`Type *`}
                                    options={options}
                                    value={currentSelect ? currentSelect : ''}
                                    onChange={id => this.handleChangeEntity('type', id.value)}
                                    style={{ height: 46 }}
                                    placeholder={`${t('Type')} *`}
                                />
                            </div>
                            <div className='col-lg-6 col-md-12 timelog-array-custom-margin'>
                                <NumberInput
                                    label={`${t(isExpence ? 'Cost' : 'Hours')} *`}
                                    value={entity.value}
                                    onChange={e => this.handleChangeEntity('value', e.target.value)}
                                    placeholder={t(isExpence ? 'Enter cost here' : 'Enter hours')}
                                    className="timelog-array__input"
                                    required
                                />
                            </div>
                        </div>
                        <div className="timelog-array__comment mb-4">
                            <Textarea
                                label={`${t('Comment')} *`}
                                value={entity.comment}
                                onChange={e => this.handleChangeEntity('comment', e.target.value)}
                                placeholder={t('Enter comments here')}
                                required
                            />
                        </div>
                        <Signature
                            onChange={signature => this.handleChangeEntity('signature', signature)}
                            initialSignature={entity.signature}
                            width="100%"
                            height={120}
                        />
                        <div className="timelog-array__attachment mt-5 w-100">
                            <div className="timelogArrayAttachmentWidget attachment-box">
                                <span className="attachment-box__label mb-2 text-color-2550AC">{t('Upload Files')}</span>
                                <AttachmentsWidget
                                    value={entity.attachIds}
                                    onChange={attachIds => this.handleChangeEntity('attachIds', attachIds)}
                                    options={{
                                        imageContext: {
                                            existingAttachment,
                                            fileType: 'docs',
                                            id: 0,
                                            model: 'Timelog',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="timelog-array__right custom-timelog-array p-0">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t('Type')}</th>
                                <th>{t('Comment')}</th>
                                <th>{t('Signature')}</th>
                                <th>{t('Amount')}</th>
                                <th>{t('Image')}</th>
                                <th>{t('Action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrayOfItems
                                && arrayOfItems.map((item, index) => (
                                    <tr key={index} className='mt-1'>
                                        <td><div className='d-flex align-items-center min-height-50'>{index + 1}</div></td>
                                        <td>{item.type}</td>
                                        <td className='timelog_array_details'>{item.comment}</td>
                                        <td className='timelog_array_details_sign'><img src={item.signature} className='timelogArraySign' /></td>
                                        <td className='timelog_array_details'>{item.value}</td>
                                        <td>{item.attachIds && item.attachIds.split(',').map(obj =>
                                            <div className='timelog-array-table-img'>
                                                <img
                                                    src={`${config.UPLOADS_API_ENDPOINT}/${obj}`}
                                                    alt="N/A"
                                                    className="uploads-item__img timelogArrayImage"
                                                    onClick={() => window.open(this.getUrl(obj), '_blank')}
                                                />
                                            </div>
                                        )}</td>
                                        <td><button
                                            type="button"
                                            className="timelog-array__col-action"
                                            onClick={() => this.removeItem(index)}
                                        >
                                            <img src={trashIcon} alt={t('Remove Item')} />
                                        </button></td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    {!arrayOfItems.length && (
                        <div className='text-center w-100'>{t('No rows found')}</div>
                    )}

                </div>
                <div className="timelog-array__footer">
                    <Button fill dynamic_lg type="submit">
                        <div className='d-flex justify-content-center'>
                            <i className='d-flex margin-right-10'>
                                <img className='me-2' src={plusIcon} alt='cross' />
                            </i>
                            {this.props.t('Save and add new')}
                        </div>
                    </Button>
                </div>
            </form>
        );
    }
}

export default TimelogArray;
