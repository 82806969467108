import React, { Fragment, Component } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import MetaTags from 'react-meta-tags';
import socketIOClient from 'socket.io-client';

// Layouts
import AdminLayout from './layouts/Admin.jsx';
// Containers
import Login from './containers/Auth/Login';
import Register from './containers/Auth/Register';
import VisitorClientRegister from './containers/Admin/Visitors/VisitorClientRegister';
import VisitorAdd from './containers/Admin/Visitors/VisitorAdd';
import ForgotPassword from './containers/Auth/ForgotPassword';

// Components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';
import {
    Home,
    VisitorRegister,
    About,
    Features,
    TimeRegister,
    SchedulePlan,
    DigitalSignature,
    EmployeeGuide,
    Checklist,
    Communication,
    Documents,
    Deviation,
    VacationAndAbsence,
    Reports,
    Contact,
    UserTerms,
    PrivacyPolicy
} from "./containers/Landing/pages";

//public page components
import DocumentReport from './containers/Admin/Document/DocumentTemplate/DocumentReport';
import ReviewAndSignDoc from './containers/Admin/SignDocs/Recipients/public';
import SignDocPdf from './containers/Admin/SignDocs/SignDocPdf';
import MultipleDocumentsPdfPreview from './containers/Admin/Document/DocumentTemplate/MultipleDocumentsPdfPreview.js';
import SignSuccess from './containers/Admin/SignDocs/SignSuccess.js';
import DocExpire from './containers/Admin/SignDocs/DocExpire.js';
import DocDeleted from './containers/Admin/SignDocs/DocDeleted.js';
import ContractPDF from './containers/Admin/DocContracts/ContractPDF.js';
import TempPage from './containers/Admin/Monitoring/TempPage.js';
import UserRegister from './containers/Admin/Users/UserRegister.js';
import PasswordSetup from './containers/Admin/Users/PasswordSetup.js';
import MultiAccountAdministrationEmailVerification from './containers/Admin/Users/MultiAccountAdminEmailVerification.js';

//config
import config from '~/library/config';

//styles
import "./styles/css/styles.css";

function PrivateRoute({ children, userStore }) {
    const { currentUser } = userStore;
    return currentUser ? <>{children}</> : <Navigate to="/" />
}

class App extends Component {
    constructor(props) {
        super(props);
        this.props.router.navigate((location, action) => {
            try {
                if (document.documentElement?.classList.value === 'nav-open')
                    document.documentElement?.classList.toggle('nav-open');
            } catch (e) {
                console.error('no open element');
            }
            const { userStore, commonStore, chatStore } = this.props;
            commonStore.log({
                username: userStore.currentUser ? userStore.currentUser.username : '',
                url: location.pathname,
                action: 'page',
                details: {},
            });
            if (userStore.currentUser) {
                chatStore.leaveRoom(); // check for route change - if leave room
            }
            if (userStore.currentUser && userStore.currentUser.is_now_locked) {
                if (location.pathname.indexOf('/locked') < 0) {
                    this.props.router.navigate("/admin/locked")
                }
            }
        });
    }

    componentWillMount() {
        const { commonStore } = this.props;
        this.setSocket();
        if (!commonStore.token) {
            commonStore.setAppLoaded();
        }
    }

    initRevisionCheck() {
        const store = this.props.commonStore;
        const interval = setInterval(async () => {
            const rev = await store.getGitRev();

            if (!store.currentGitRev) {
                store.setGitRev(rev);
            } else if (rev !== store.currentGitRev) {
                alert('New version is available, please reload the page');
                clearInterval(interval);
            }
        }, 5 * 60 * 1000);
    }

    componentDidMount() {
        const { commonStore, userStore, location, chatStore } = this.props;

        if (commonStore.token) {
            userStore
                .pullUser()
                .then(() => {
                    this.setSocket();
                })
                .finally(() => {
                    commonStore.setAppLoaded();
                    userStore.loadNotifications();
                    chatStore.loadRooms();
                    chatStore.loadPrivateRooms();
                    if (userStore.currentUser && userStore.currentUser.is_now_locked) {
                        if (location.pathname.indexOf('/locked') < 0) {
                            this.props.router.navigate("/admin/locked")
                        }
                    }
                });
            const tz = new Date().getTimezoneOffset();
            if (tz !== -120 && tz !== -180 && tz !== -60) {
                // alert(
                //     t(
                //         'Clock timezone is probably invalid. Are you using Chrome on Android? If so, please change browser.'
                //     )
                // );
            }
        }

        this.initRevisionCheck();
    }

    setSocket() {
        const { chatStore, userStore } = this.props;
        const socket = socketIOClient(config.SOCKET_ROOT);
        socket.on('connect', (data) => {
            chatStore.setupIo(socket);
            userStore.setupIo(socket);
        });
        socket.on('connection_established', (data) => {
            chatStore.setupIo(socket);
            userStore.setupIo(socket);
        });
    }

    render() {
        const { commonStore, userStore } = this.props;
        let scale = 1;
        if (commonStore.appLoaded) {
            return (
                <Fragment>
                    <MetaTags>
                        <meta name="viewport" content={`width=device-width, initial-scale=${scale}`} />
                    </MetaTags>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/visitorClientRegister" element={<VisitorClientRegister />} />
                        <Route path="/visitorRegister" element={<VisitorAdd />} />
                        <Route path="/forgot" element={<ForgotPassword />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/terms" element={<UserTerms />} />
                        <Route path="/visitor-register" element={<VisitorRegister />} />
                        <Route path="/about-us" element={<About />} />
                        <Route path="/features" element={<Features />} />
                        <Route path="/time-register" element={<TimeRegister />} />
                        <Route path="/schedule-plan" element={<SchedulePlan />} />
                        <Route path="/digital-signature" element={<DigitalSignature />} />
                        <Route path="/employee-guide" element={<EmployeeGuide />} />
                        <Route path="/checklist" element={<Checklist />} />
                        <Route path="/communication" element={<Communication />} />
                        <Route path="/documents" element={<Documents />} />
                        <Route path="/deviation" element={<Deviation />} />
                        <Route path="/vacation-and-absence" element={<VacationAndAbsence />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/contact-us" element={<Contact />} />
                        <Route path="/docexpire" element={<DocExpire />} />
                        <Route path="/docdeleted" element={<DocDeleted />} />
                        <Route path="/signsuccess" element={<SignSuccess />} />
                        <Route path="/multipleDocumentsPdfPreview/*" element={<MultipleDocumentsPdfPreview />} />
                        <Route path="/documentReport/*" element={<DocumentReport />} />
                        <Route path="/signdoc/pdf/*" element={<SignDocPdf />} />
                        <Route path="/signdoc/*" element={<ReviewAndSignDoc />} />
                        <Route path="/contract/pdf/*" element={<ContractPDF />} />
                        <Route path="/monitor/report/*" element={<TempPage />} />
                        <Route path="/user-register/*" element={<UserRegister />} />
                        <Route path="/user-update-password/*" element={<PasswordSetup />} />
                        <Route path="/multiAccountAdministration/*" element={<MultiAccountAdministrationEmailVerification />} />
                        <Route path="/admin/*" element={<PrivateRoute userStore={userStore}><AdminLayout /></PrivateRoute>} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </Fragment>
            );
        }
        return null;
    }
}

export default inject('userStore', 'commonStore', 'chatStore')(withLocalization(applicationRouter(observer(App))));
