import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find, filter } from 'lodash';

//components
import withLocalization from '../../../hoc/withLocalization';

//elements
import Select from '../../../elements/Select';
import CustomMultiSelect from '../../../elements/CustomMultiSelect';

const UsersWidgetMobx = inject('userStore')(withLocalization(observer(class UsersWidgetMobx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            selectValue: '',
            multiSelectOptions: [],
            findChatUsers: []
        };
    }

    async componentDidMount() {
        if (this.props.directList) {
            return;
        }

        const { options } = this.props;
        const project = options && options.project && options.project.project_id ? options.project.project_id : "";

        if (this.props.isSelectMultiple) {
            this.props.userStore.loadLookup(this.props.mode, '', project).then(() => {
                const key = `${this.props.mode || 'all'}${project}`;
                const multiSelectOptions = this.prepareOptions(this.props.userStore.currentMembersLookup[key]);
                let selectValue = [];
                const valueArr = this.props.value ? this.props.value.split(',') : [];
                if (valueArr.length) {
                    multiSelectOptions.map(obj => {
                        if (valueArr.includes(obj.value)) selectValue.push(obj.value);
                    })
                }
                this.setState({ multiSelectOptions, selectValue });
            });
        }
        else if (this.props.isSelectMultipleChat) {
            this.props.userStore.loadLookup(this.props.mode, '').then(() => {
                const userOptions = this.props.userStore.currentMembersLookup.both || [];
                const currentUser = this.props.userStore.currentUser;
                const findChatUsersdata = userOptions.filter(ele => ele.id != currentUser.id);
                const findChatUsers = findChatUsersdata.map(obj => { return { value: `${obj.id}`, label: `${obj.fullname}` } })
                let selectValue = [];
                const valueArr = this.props.value ? this.props.value.split(',') : [];
                if (valueArr.length) {
                    findChatUsers.map(obj => {
                        if (valueArr.includes(obj.value)) selectValue.push(obj.value);
                    })
                }
                this.setState({ selectValue, findChatUsers });
            })
        }
        else {
            this.props.userStore.loadLookup(this.props.mode, '', project);
        }
    }

    loadOptions = (inputValue, callback) => {
        const { mode, userStore, directList, options, IsRequestFromReport, valuesToFilter } = this.props;
        const { currentMembersLookup } = userStore;
        const project = options && options.project && options.project.project_id ? options.project.project_id : "";
        const key = `${mode || 'all'}${project}${inputValue || ''}`;
        if (directList) {
            callback(this.prepareOptions(directList, valuesToFilter));
            return;
        }
        this.props.userStore.loadLookup(this.props.mode, inputValue, project, IsRequestFromReport).then(() => {
            const options = this.prepareOptions(currentMembersLookup[key], valuesToFilter);
            callback(options);
            if (this.props.value && this.props.isMulti) {
                const currentSelect = filter(options, o => {
                    return this.props.value.indexOf(String(o.value)) >= 0;
                });
                this.setState({ selectValue: currentSelect });
            }
        });
    };

    changeMultiSelect = (value) => {
        this.setState({ selectValue: value });
    }

    handleInputChange = newValue => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    prepareOptions = (array, valuesToFilter = []) => {
        const { emptyOption, emptyOptionLabel } = this.props;
        let result = array
            ? array.map(pm => {
                return { value: `${pm.id}`, label: `${pm.fullname}` };
            })
            : [];
        if (valuesToFilter && valuesToFilter.length && result && result.length) {
            const filteredArray = result.filter(user => valuesToFilter.indexOf(Number(user.value)) !== -1);
            result = filteredArray;
        }
        if (emptyOption) {
            result.unshift({
                value: '',
                label: emptyOptionLabel,
            });
        }
        return result;
    };

    render() {
        const { directList, isSelectMultiple, isSelectMultipleChat } = this.props;
        const { currentMembersLookup } = this.props.userStore;
        const {
            value,
            onChange,
            mode,
            options:propOptions,
            isMulti,
            storeAsString,
            disabled,
            label,
            emptyOption,
            emptyOptionLabel,
            placeholder = 'Select...',
            storeAsStringAndFull,
            valuesToFilter,
            reactSelectClassname
        } = this.props;
        const { inputValue, multiSelectOptions, findChatUsers } = this.state;
        const project = propOptions && propOptions.project && propOptions.project.project_id ? propOptions.project.project_id : "";
        const key = `${mode || 'all'}${project}${inputValue || ''}`;
        const _options = this.prepareOptions(directList || currentMembersLookup[key], valuesToFilter ? valuesToFilter : []);

        let currentSelect = null;
        if (isMulti) {
            if (this.state.selectValue) {
                currentSelect = this.state.selectValue;
            } else {
                currentSelect = find(_options, o => {
                    return String(o.value) === String(value);
                });
            }
        } else if (isSelectMultiple) {
            currentSelect = this.state.selectValue;
        } else if (isSelectMultipleChat) {
            currentSelect = this.state.selectValue;
        }
        else {
            currentSelect = filter(_options, o => {
                return String(o.value) == String(value);
            });
        }
        const inputView = false;
        return (
            <div className={`managers-widget ${inputView ? 'input-block' : ''}`}>
                {inputView && <span className="input-block__label">{label}</span>}
                {!isSelectMultiple && !isSelectMultipleChat &&
                    <>
                        {mode === 'managers' ? (
                            <Select
                                className={reactSelectClassname || ""}
                                options={_options}
                                value={currentSelect}
                                isDisabled={disabled}
                                onChange={id => onChange(id.value, id)}
                                style={{ width: '100%' }}
                            />
                        ) : (
                            <Select
                                className={reactSelectClassname || ""}
                                asyncSelect
                                cacheOptions
                                emptyOption={emptyOption}
                                emptyOptionLabel={emptyOptionLabel}
                                isDisabled={disabled}
                                isMulti={isMulti}
                                inputView={inputView}
                                loadOptions={this.loadOptions}
                                defaultOptions
                                value={currentSelect}
                                onInputChange={this.handleInputChange}
                                placeholder={placeholder}
                                onChange={id => {
                                    this.setState({ selectValue: id });
                                    if (storeAsString) {
                                        let map = '';
                                        id.forEach(r => {
                                            if (map) map += ',';
                                            map += r.value;
                                        });
                                        onChange(map, id);
                                    } else {
                                        onChange(id ? id.value : null, id);
                                    }
                                }}
                                style={{ width: '100%' }}
                            />
                        )}
                    </>
                }
                {isSelectMultiple &&
                    <CustomMultiSelect
                        options={multiSelectOptions}
                        value={currentSelect}
                        className={"custom-multi-select-max-height-200"}
                        onChange={selected => {
                            this.setState({ selectValue: selected }, () => {
                                if (storeAsString) {
                                    const str = selected.join(',');
                                    onChange(str, selected);
                                } else if (storeAsStringAndFull) {
                                    const str = selected.join(',');
                                    const full = multiSelectOptions.filter(option => selected.indexOf(Number(option.value)) !== -1);
                                    onChange(str, selected, full);
                                } else {
                                    const full = multiSelectOptions.filter(option => selected.indexOf(option.value) !== -1);
                                    onChange(selected, full);
                                }
                            })
                        }}
                    />
                }
                {isSelectMultipleChat &&
                    <CustomMultiSelect
                        options={findChatUsers}
                        value={currentSelect}
                        className={'custom-border-radius-50'}
                        onChange={selected => {
                            this.setState({ selectValue: selected }, () => {
                                if (storeAsString) {
                                    const str = selected.join(',');
                                    onChange(str, selected);
                                } else if (storeAsStringAndFull) {
                                    const str = selected.join(',');
                                    const full = findChatUsers.filter(option => selected.indexOf(option.value) !== -1);
                                    onChange(str, selected, full);
                                } else {
                                    const full = findChatUsers.filter(option => selected.indexOf(option.value) !== -1);
                                    onChange(selected, full);
                                }
                            })
                        }}
                    />
                }
            </div>
        );
    }
})));

export const ManagersWidget = observer(class ManagersWidget extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} mode="managers" />;
    }
});

export const MembersWidget = observer(class MembersWidget extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} mode="members" />;
    }
});

export const MembersWidgetMultiple = observer(class MembersWidgetMultiple extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} storeAsString isMulti mode="members" />;
    }
});

export const MembersWidgetMultipleSelect = observer(class MembersWidgetMultipleSelect extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} storeAsStringAndFull isSelectMultiple mode="members" />;
    }
});

export const MembersWidgetMultipleSelectforAll = observer(class MembersWidgetMultipleSelectforAll extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} storeAsStringAndFull isSelectMultipleChat mode="both" />;
    }
});

export const AllUsersWidget = observer(class AllUsersWidget extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} mode="both" />;
    }
});

export const AllUsersInCompanyWidget = observer(class AllUsersInCompanyWidget extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} mode="everyone" />;
    }
});
