import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';

// Components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ChecklistTable from './ChecklistTable';
import ChecklistAssign from './ChecklistAssign';
import GenericList from '~/components/GenericList/GenericList';
import SwitchWidget from '../ControlPanel/SwitchWidget';
import GenericFilter from '../../../components/GenericList/GenericFilter';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';

//styles
import '../../../styles/css/styles.css';

//assets
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg'

class ChecklistTableAd extends Component {
    state = {
        add: false,
        currentChecklistId: 0,
        showChecklistTodo: false,
        showChecklistAccept: false,
        currentChecklist: {},
        sureModal: false,
        isGoBack: false,
    };

    handleSwitch = (id, value, index) => {
        const { t, commonStore, checklistStore } = this.props;
        const { currentChecklist: checklist } = this.props.checklistStore;

        let hydrated = {
            id: id,
            cornjob_type: checklist.cornjob_type,
            cornjob_duration: checklist.cornjob_duration,
        };
        checklistStore
            .scheduleToNone(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Successfully turned off the schedule to None'), null, 'success');
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Cant able to find id'), null, 'error');
            });
    };

    renderTableActionButtons = (id, full) => {
        let statusText = full.status;
        if (statusText !== 'Approved') statusText = 'active';
        let statusIcon = <i className={'pe-7s-hourglass'} />;
        if (full.status === 'Approved') {
            statusIcon = <i className={'pe-7s-check'} />;
        }

        return (
            <div>
                <SwitchWidget
                    value={full.job_type !== 'None' ? true : false}
                    onChange={(value) => this.handleSwitch(id, value)}
                    disabled={full.job_type !== 'None' ? false : true}
                />
            </div>
        );
    };

    viewChecklists = () => {
        this.setState({ isGoBack: true });
    };
    viewChecklistAssign = () => {
        this.setState({ add: true, currentChecklistId: 0 });
        this.renderChecklistAssignModal();
    };

    renderChecklistAssignModal = () => {
        this.setState({
            showChecklistAssign: true,
        });
    };
    handleClosesChecklisAssignModal = () => {
        this.setState({ showChecklistAssign: false, add: false });
        this.props.checklistStore.returnDefaultNew();
    };

    applyForm = (params = {}) => {
        const { currentChecklist: checklist } = this.props.checklistStore;
        const { t, commonStore, checklistStore } = this.props;

        if (
            checklist.project_id === null ||
            checklist.project_id === '' ||
            checklist.checklist_templates_id === null ||
            checklist.checklist_templates_id === ''
        ) {
            commonStore.addNotification(t('Please fill all required fields'), null, 'error');
            return;
        }

        if (
            (checklist.cornjob_type === 'Gap Between Days' &&
                (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === '')) ||
            (checklist.cornjob_type === 'Weekdays' &&
                (checklist.cornjob_duration === '' || !isNaN(checklist.cornjob_duration))) ||
            (checklist.cornjob_type === 'Monthly' &&
                (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === '')) ||
            (checklist.cornjob_type === 'Half Yearly' &&
                (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === '')) ||
            (checklist.cornjob_type === 'Annually' &&
                (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ''))
        ) {
            commonStore.addNotification(t('Please fill all required fields'), null, 'error');
            return;
        }
        let hydrated = {
            user_id: checklist.user_id,
            project_id: checklist.project_id,
            checklist_templates_id: checklist.checklist_templates_id,
            //to_project: checklist.to_project,
            isSendEmail: checklist.isSendEmail,
            cornjob_type: checklist.cornjob_type,
            cornjob_duration: checklist.cornjob_duration,
            isobligatory: checklist.isobligatory,
            allow_check_all: checklist.allow_check_all,
        };
        if (checklist.id && checklist.id > 0) {
            hydrated['id'] = checklist.id;
        }

        checklistStore
            .assignChecklist(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Checklist assign sucessfully.'), null, 'success');
                this.handleClosesChecklisAssignModal();
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Please fill all required fields'), null, 'error');
            });
    };

    setStatusColor = (status) => {
        let color = '';
        switch (status) {
            case 'Done':
                color = 'green';
                break;

            case 'Approved':
                color = 'green';
                break;

            case 'Deviation':
                color = 'red';
                break;

            case 'Under work':
                color = '#ff8c00';
                break;

            default:
                color = 'blue';
        }
        return color;
    };

    render() {
        const { t } = this.props;
        const { loading, filters, appliedFilters } = this.props.checklistStore;
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <>
                {this.state.isGoBack && <ChecklistTable />}
                {!this.state.isGoBack && (
                    <div>
                        <div>
                            <div className="table-list">
                                <div className="table-list__header">
                                    {this.props.userStore.currentUser.user_type !== 'member' && (
                                        <Button fill wd onClick={() => this.viewChecklists()}>
                                            {t('All')} {t('Checklists')}
                                        </Button>
                                    )}
                                    <Button fill wd onClick={() => this.viewChecklistAssign()}>
                                        {t('Do Checklists')}
                                    </Button>
                                </div>
                                <GenericList
                                    columns={[
                                        {
                                            Header: 'Id',
                                            id: 'id',
                                            accessor: 'id',
                                            minWidth: 20,
                                            maxWidth: 30,
                                        },
                                        {
                                            Header: 'Name',
                                            id: 'name',
                                            accessor: ({ id, name }) => <span>{`${name}`}</span>,
                                            maxWidth: 80,
                                            minWidth: 60,
                                        },
                                        {
                                            Header: 'User',
                                            id: 'user_name',
                                            accessor: ({ first_name, last_name, to_project }) =>
                                                to_project === false ? (
                                                    <span>{`${first_name}` + ' ' + `${last_name}`}</span>
                                                ) : (
                                                    'To Project'
                                                ),
                                            maxWidth: 80,
                                            minWidth: 60,
                                        },
                                        {
                                            Header: 'Project',
                                            id: 'project_name',
                                            accessor: 'project_name',
                                            maxWidth: 80,
                                            minWidth: 60,
                                        },
                                        {
                                            Header: 'Assigned by',
                                            id: 'client_name',
                                            accessor: ({ created_by_first_name, created_by_last_name }) => (
                                                <span>
                                                    {`${created_by_first_name}` + ' ' + `${created_by_last_name}`}
                                                </span>
                                            ),
                                            maxWidth: 80,
                                            minWidth: 60,
                                        },
                                        {
                                            Header: 'Status',
                                            id: 'status',
                                            accessor: ({ status }) => (
                                                <span
                                                    style={{
                                                        color: this.setStatusColor(status),
                                                    }}
                                                >
                                                    {status}
                                                </span>
                                            ),
                                            maxWidth: 80,
                                            minWidth: 60,
                                        },
                                        {
                                            Header: 'Last Updated',
                                            id: 'updated_at',
                                            accessor: ({ updated_at }) => (
                                                <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                                            ),
                                            maxWidth: 80,
                                            minWidth: 60,
                                        },
                                        {
                                            Header: 'Next Schedule',
                                            id: 'next_schedule',
                                            accessor: ({ next_day }) => (
                                                <span>{next_day && shortDateFormat(next_day, dateTimeRules)}</span>
                                            ),
                                            maxWidth: 80,
                                            minWidth: 60,
                                        },
                                        {
                                            Header: 'Actions',
                                            id: 'actions',
                                            accessor: (full) => this.renderTableActionButtons(full.id, full),
                                            maxWidth: 80,
                                            minWidth: 60,
                                        },
                                    ]}
                                    forceReload={
                                        this.state.checklistChanged === true ||
                                        this.props.checklistStore.deleteSuccess ||
                                        this.props.checklistStore.addOrUpdated
                                    }
                                    filters={appliedFilters}
                                    lastListLoadTime={this.props.checklistStore.lastListLoadTime}
                                    header={
                                        <GenericFilter
                                            filters={filters}
                                            setFilter={(name, value) =>
                                                this.props.checklistStore.setFilter(name, value)
                                            }
                                            onFilter={() => this.props.checklistStore.onFilter()}
                                            loading={loading}
                                            hasProjectFilter={true}
                                        />
                                    }
                                    requestData={(params) => this.props.checklistStore.getChecklistsFilter(params)}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <Modal
                    className="modal right fade"
                    show={this.state.showChecklistAssign}
                    onHide={this.handleClosesChecklisAssignModal}
                >
                    <Modal.Header>
                        <Modal.Title>{t('Do Checklists')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleClosesChecklisAssignModal}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <ChecklistAssign
                            add={this.state.add}
                            id={this.state.currentChecklistId}
                            handleClosesChecklisAssignModal={this.handleClosesChecklisAssignModal}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.applyForm} className="btn-wd btn-fill">
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleClosesChecklisAssignModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default inject(
    'checklistStore',
    'authStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(ChecklistTableAd)))));
