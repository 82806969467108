import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import '../../../styles/css/styles.css';
import Apps from '../../../assets/img/Apps.svg'
import checklist_sign from '../../../assets/img/sign-logo.svg'
import clock_checklist from '../../../assets/img/clock-checklist.svg'
import photo_checklist from '../../../assets/img/photo-checklist.svg'
// Components
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomCheckBox from '../../../components/CustomCheckbox/CustomCheckbox';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { NumberInput } from '../../../elements/Input';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import Radio from '../../../components/CustomRadio/CustomRadio.jsx';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import moment from 'moment/min/moment-with-locales';
import { ChecklistWidget } from '../../../components/GenericForm/Widgets/ChecklistWidget';
// import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import Select from '../../../elements/Select';
// import TrueFalseWidget from '../../../components/GenericForm/Widgets/TrueFalseWidget';
import checklistStore from '../../../library/stores/checklistStore';

const cornjob_typeOptions = [{ label: 'Everyday', value: 'Everyday', title: 'Everyday' }, 
{ label: 'Weekdays', value: 'Weekdays', title: 'Weekdays' },
{ label: 'Gap Between Days', value: 'Gap Between Days', title: 'Gap Between Days' },
{ label: 'Monthly', value: 'Monthly', title: 'Monthly' }, 
{ label: 'Half Yearly', value: 'Half Yearly', title: 'Half Yearly' },
{ label: 'Annually', value: 'Annually', title: 'Annually' }, 
{ label: 'Date Of Month', value: 'Date Of Month', title: 'Date Of Month' }, 
{ label: 'None', value: 'None', title: 'None' }]

const WeekDaysOptions = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

class ChecklistTemplateAssign extends Component {
    state = {
        checklist: {},
        showErrors: false,
        isProjectMembers: false,
        projectMembers: [],
        selectedMember: '',
        all:false,
        selected_date:null,
        currentSelectedChecklist: [],
        date: new Date()
    }

    loadData = async () => {
        const { checklistStore, id, add } = this.props;
        checklistStore.updateIsAllChecklistShow(false);
        const { userStore: { currentUser } } = this.props;
        let user_type = currentUser.user_type;
        let user_id = currentUser.id;

        if (add) {
            checklistStore.returnDefaultNew();
            if (user_type === 'member') {
                this.handleChange('user_id', user_id);
            }
            return;
        } else {
            checklistStore.updateIsAllChecklistShow(true);
            this.setState({all: true});
        }
        if (user_type === 'member') {
            this.handleChange('user_id', user_id);
        }

        const checklistTemplateId = await checklistStore.load(id, add).then(
            (response) => {
                this.loadProjectMembers(checklistStore.currentChecklist.project_id)
                return response;
            }
        );
        const selectedChecklist = await this.props.checklistStore.currentChecklistLookup.find(ele => ele.id === checklistTemplateId.checklist[0].checklist_templates_id)
        this.setState({currentSelectedChecklist: selectedChecklist})
    }

    componentWillMount() {
        this.props.checklistStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    handleChange = (name, value) => {
        this.props.checklistStore.handleChange(name, value);
        if (name === "cornjob_type" && (value === "Monthly" || value === "Half Yearly" || value === "Annually")) {
            
            if (value === "Monthly") {
                value = 30;
            } else if (value === "Half Yearly") {
                value = 182;
            } else if (value === "Annually") {
                value = 365;
            }
            this.props.checklistStore.handleChange('cornjob_duration', value);
            const getDate = moment(new Date()).add(value,"days").toDate();
            this.setState({selected_date:getDate});
        }
        if(name === "cornjob_duration"){
            const getDate = moment(new Date()).add(value,"days").toDate();
            this.setState({selected_date:getDate});
        }
        //load members according to project in select input//
        if (name === "project_id") {
            this.loadProjectMembers(value)
        }
        if(name === 'checklist_templates_id') {
            const selectedChecklist = this.props.checklistStore.currentChecklistLookup.find(ele => ele.id == value)
            this.setState({currentSelectedChecklist: selectedChecklist})
        }
        if(name === 'date_of_month' && value){
            this.setState({date: value})
        }
    };

    //handles change in select input//
    handleSelect = (member, type) => {
        this.setState({ selectedMember: member })
        if (type === 'change') {
            this.handleChange('user_id', member.value)
        }

    }

    //loads projectMemberOptions in select according to projectId//
    loadProjectMembers = async (id) => {
        const { userStore, add, checklistStore } = this.props;
        await userStore.loadByProjectId(id).then(
            (data) => {
                const result = data ? data.map(element => {
                    return { value: `${element.id}`, label: `${element.fullname}` }
                })
                    : [];
                this.setState({ projectMembers: result }, () => {
                    if (!add) {
                        let member = result.filter(item => item.value === checklistStore.currentChecklist.user_id)
                        this.handleSelect(member, 'load');
                    }
                    this.setState({ isProjectMembers: true })
                })
            }
        )
    }

    handleUserChange = (value, full) => {
        this.handleChange('user_id', value);
    };

    // parseCronJobDays = () => {
    //     const { currentChecklist } = this.props.checklistStore
    //     let idsArray = []
    //     if (currentChecklist.cornjob_duration != null && currentChecklist.cornjob_duration != "") {
    //         idsArray = currentChecklist.cornjob_duration.split(",").map(function (item) {
    //             return parseInt(item, 10);
    //         });
    //     }
    //     return idsArray;
    // }

    handleCornjobDurationChange(day, value) {
        const { currentChecklist } = this.props.checklistStore;
        let weekDayIds = [];
        if(currentChecklist.cornjob_duration !== "" && isNaN(currentChecklist.cornjob_duration))
            weekDayIds = currentChecklist.cornjob_duration.split(",");
        if (value) {
            if (!weekDayIds.includes(day))
                weekDayIds.push(day);
        }
        else {
            if (weekDayIds.includes(day))
                weekDayIds.splice(weekDayIds.indexOf(day), 1);
        }
        this.handleChange("cornjob_duration", weekDayIds.toString())
    }

    render() {
        const { t, userStore: { currentUser }, userStore } = this.props;
        const { currentChecklist: checklist, isAllChecklistShow } = this.props.checklistStore;
        const { showErrors, projectMembers, selectedMember, currentSelectedChecklist } = this.state;
        // let reportedUsername = `${currentUser.first_name} ${currentUser.last_name}`;
        const user_type = currentUser.user_type;
        return (
            <div className="page p-0 m-0 h-auto">
                <div className="page__content">
                    <form className="custom-form">
                        <div className="custom-form__row">
                            <div className={currentSelectedChecklist?.element_data ? "col-md-6" : "col-md-6"}>
                                <label className="custom-form__col">
                                    <ProjectsWidget
                                        label={`${t('Project')} *`}
                                        value={checklist.project_id}
                                        // _module={'checklist_templates'}
                                        allowAll={false}
                                        onChange={value => {
                                            this.handleChange('project_id', value);
                                        }}
                                        error={showErrors && !checklist.project_id}
                                    />
                                </label>
                                <label className="custom-form__col mb-4 pt-0 pb-0">
                                    <CustomCheckBox
                                        label={`${t('Show all available checklists')}`}
                                        disabled={!checklist.project_id}
                                        checked={this.state.all}
                                        onChange={value => {
                                            checklistStore.updateIsAllChecklistShow(value);
                                            this.setState({ "all": value })
                                            this.handleChange('all', isAllChecklistShow);
                                        }}
                                        labelClassName={'checkList-checkbox-bottom'}
                                    />
                                </label>
                                <div className="custom-form__col">
                                    <ChecklistWidget
                                        placeholder={t('Select Checklist')}
                                        label={`${t('Checklist')} *`}
                                        value={checklist.checklist_templates_id}
                                        filterByProject={checklist.project_id}
                                        defaultIfOne
                                        disabled={!checklist.project_id}
                                        onChange={value => {
                                            this.handleChange('checklist_templates_id', value);
                                        }}
                                        all={isAllChecklistShow}
                                    />
                                </div>
                                <div className="custom-form__row">
                                    {user_type !== 'member' && (
                                        <div className="custom-form__col">
                                            <div className="form-group field field-string">
                                                <label className="control-label schedule-text text-dark"><strong>{t('Select Member (If you want to assign the checklist for selected member)')} *</strong></label>
                                                {(
                                                    <Select
                                                        options={projectMembers}
                                                        value={selectedMember}
                                                        //isDisabled={!isProjectMembers}
                                                        onChange={member => this.handleSelect(member, 'change')}
                                                        placeholder='Select...'
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="custom-form__row p-2">
                                    <div className="custom-form__col__checklist">
                                        <CustomCheckBox
                                            label={`${t('Obligatory')}`}
                                            checked={checklist.isobligatory}
                                            onChange={evt => { this.handleChange('isobligatory', evt) }}
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                    </div>
                                    <div className="custom-form__col__checklist">
                                        <CustomCheckBox
                                            label={`${t('Send Mail')}`}
                                            checked={checklist.isSendEmail}
                                            onChange={evt => { this.handleChange('isSendEmail', evt) }}
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                    </div>
                                    <div className="custom-form__col__checklist">
                                        <CustomCheckBox
                                            label={`${t('Allow Check all')}`}
                                            checked={checklist.allow_check_all}
                                            onChange={evt => { this.handleChange('allow_check_all', evt) }}
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                    </div>
                                </div>
                                <div className="schedule">

                                    <p className="schedule-header">{t('Schedule')}</p>
                                    <p className="schedule-text">{t('By selecting below options your checklist will be created')}</p>

                                    <div className="schedule-options">
                                        {cornjob_typeOptions.map(radio => (
                                            <div style={{ marginRight: '1rem' }} key={radio.value}>
                                                <Radio
                                                    key={radio.value}
                                                    name={radio.label}
                                                    value={radio.value}
                                                    label={t(radio.label)}
                                                    checked={checklist.cornjob_type === radio.value}
                                                    onChange={() => {
                                                        if (radio.value === "Everyday" || radio.value === "Weekdays") {
                                                            this.handleChange("cornjob_type", radio.value);
                                                            this.handleChange("cornjob_duration", "");
                                                        }
                                                        else
                                                            this.handleChange("cornjob_type", radio.value);
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {checklist.cornjob_type === "Weekdays" &&
                                        <div className="schedule-options">
                                            {WeekDaysOptions.map((day, index) => (
                                                <div style={{ marginRight: '1rem' }} key={index}>
                                                    <CustomCheckBox
                                                        label={`${t(day)}`}
                                                        className="custom-form__col"
                                                        checked={checklist.cornjob_duration && checklist.cornjob_duration.split(',').some(item => item === day)}
                                                        onChange={evt => this.handleCornjobDurationChange(day, evt)}
                                                        labelClassName={'checkList-checkbox-bottom'}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {checklist.cornjob_type === "Gap Between Days" &&
                                        <>
                                            <div className="custom-form__row">
                                                <NumberInput
                                                    className="custom-form__col"
                                                    label={`${t('Gap Between Days')} *`}
                                                    placeholder={t('Enter Gap')}
                                                    name="cornjob_duration"
                                                    value={checklist.cornjob_duration}
                                                    onChange={evt => {
                                                        if ((evt.target.value > 0 && evt.target.value < 366) || evt.target.value === "")
                                                            this.handleChange("cornjob_duration", evt.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="custom-form__row">
                                                <div className='p-3 w-75'>
                                                    <DatePicker
                                                        selected={this.state.selected_date}
                                                        style={{ width: '10px' }}
                                                        dateFormat="EEEE MMMM dd, yyyy"
                                                        disabled />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {checklist.cornjob_type === "Monthly" &&
                                        <>
                                            <div className="custom-form__row">
                                                <NumberInput
                                                    className="custom-form__col"
                                                    label={`${t('Days')} *`}
                                                    placeholder={t('Enter Days')}
                                                    name="cornjob_duration"
                                                    value={checklist.cornjob_duration}
                                                    onChange={evt => {
                                                        if ((evt.target.value > 0 && evt.target.value < 32) || evt.target.value === "")
                                                            this.handleChange("cornjob_duration", evt.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="custom-form__row">
                                                <div className='p-3 w-75'>
                                                    <DatePicker
                                                        selected={this.state.selected_date}
                                                        style={{ width: '10px' }}
                                                        dateFormat="EEEE MMMM dd, yyyy"
                                                        disabled />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {checklist.cornjob_type === "Half Yearly" &&
                                        <>
                                            <div className="custom-form__row">
                                                <NumberInput
                                                    className="custom-form__col"
                                                    label={`${t('Days')} *`}
                                                    placeholder={t('Enter Days')}
                                                    name="cornjob_duration"
                                                    value={checklist.cornjob_duration}
                                                    onChange={evt => {
                                                        if ((evt.target.value > 0 && evt.target.value < 183) || evt.target.value === "")
                                                            this.handleChange("cornjob_duration", evt.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="custom-form__row">
                                                <div className='p-3 w-75'>
                                                    <DatePicker
                                                        selected={this.state.selected_date}
                                                        style={{ width: '10px' }}
                                                        dateFormat="EEEE MMMM dd, yyyy"
                                                        disabled />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {checklist.cornjob_type === "Annually" &&
                                        <>
                                            <div className="custom-form__row">
                                                <NumberInput
                                                    className="custom-form__col"
                                                    label={`${t('Days')} *`}
                                                    placeholder={t('Enter Days')}
                                                    name="cornjob_duration"
                                                    value={checklist.cornjob_duration}
                                                    onChange={evt => {
                                                        if ((evt.target.value > 0 && evt.target.value < 366) || evt.target.value === "")
                                                            this.handleChange("cornjob_duration", evt.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="custom-form__row">
                                                <div className='p-3 w-75'>
                                                    <DatePicker
                                                        selected={this.state.selected_date}
                                                        style={{ width: '10px' }}
                                                        dateFormat="EEEE MMMM dd, yyyy"
                                                        disabled />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {checklist.cornjob_type === "Date Of Month" &&
                                        <>
                                            <div className="custom-form__row">
                                                <div className='p-3 w-75'>
                                                    <DatePicker
                                                        selected={this.state.date}
                                                        style={{ width: '10px' }}
                                                        dateFormat="EEEE MMMM dd, yyyy"
                                                        onChange={(date) => this.handleChange('date_of_month',date)}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="custom-form__row">
                                        {user_type === 'member' && (
                                            <div className="custom-form__col">
                                                <div className="read-area">
                                                    <span className="read-area__label">{t('Member')}</span>
                                                    <div className="read-area__content">{userStore.currentUser.first_name} {userStore.currentUser.last_name}</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={currentSelectedChecklist?.element_data ? 'col-md-6 checklist-assign-card': ''}>
                                {currentSelectedChecklist?.element_data ?
                                    currentSelectedChecklist?.element_data.map((items,index) => (
                                        <div className=''>
                                            <>
                                                <div className={`${index === 0 ? '' : 'mt-3'} checklist-assign-card-element`}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div>
                                                            <div className='reportsTitle'>{items.name}</div>
                                                            <div className=''>{items.comment}</div>
                                                        </div>
                                                        <div className='d-flex flex-row w-50 justify-content-around'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='checklist-assign-element-checkbox'>
                                                                    <CustomCheckBox
                                                                        disabled={true}
                                                                        checked={items.siginig_required}
                                                                        labelClassName={'checkList-checkbox-bottom'}
                                                                    />
                                                                </div>
                                                                <img src={checklist_sign} alt='sign' />
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='checklist-assign-element-checkbox'>
                                                                    <CustomCheckBox
                                                                        disabled={true}
                                                                        checked={items.due_date_required}
                                                                        labelClassName={'checkList-checkbox-bottom'}
                                                                    />
                                                                </div>
                                                                <img src={clock_checklist} alt='clock' />
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='checklist-assign-element-checkbox'>
                                                                    <CustomCheckBox
                                                                        disabled={true}
                                                                        checked={items.photo_required}
                                                                        labelClassName={'checkList-checkbox-bottom'}
                                                                    />
                                                                </div>
                                                                <img src={photo_checklist} alt='clock' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {items.elements.length ? items.elements.map((item, index) => (
                                                        <div className='checklist-element-divider'>
                                                            <div className='mt-2'>
                                                                <div className='mb-10'>
                                                                    <div>
                                                                        <div className='d-flex'>
                                                                            <img src={Apps} alt='Apps' />
                                                                            <div className='reportsTitle ml-10'>{item.name}</div>
                                                                        </div>
                                                                        <div className='ml-30 w-50'>{item.comment}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item.elements.map(ele => (
                                                                <div className='checklist-element-divider ml-30'>
                                                                    <div className='mb-10'>
                                                                        <div>
                                                                            <div className='d-flex'>
                                                                                <div className='reportsTitle'>{ele.name}</div>
                                                                            </div>
                                                                            <div className='w-50'>{ele.name}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </>
                                        </div>
                                    ))
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default inject('checklistStore', 'authStore', 'userStore', 'commonStore')(
    applicationRouter(withLocalization(withRemoveDialog(observer(ChecklistTemplateAssign))))
);
