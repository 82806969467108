import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';

// Components
import GenericList from '../../../components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter';
import DeviationUrgencyMarker from './DeviationUrgencyMarker';
import withLocalization from '../../../hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';

class DeviationList extends Component {
    componentDidMount() {
        const query = queryString.parse(this.props.router.location.search);
        Object.keys(query).forEach((key) => {
            if (key === 'status' || key === 'reported_by_id' || key === 'assigned_id') {
                this.props.deviationStore.setFilter(key, query[key]);
            }
        });
        this.props.deviationStore.onFilter();
    }

    renderTableActionButtons = (id) => {
        const {
            userStore: { currentUser },
        } = this.props;

        const userType = currentUser.user_type;
        return (
            <div className="actions-center actions-center__center">
                <Button icon_sm fill onClick={() => this.props.onDetails(id)}>
                    <img src={Edit} alt="edit" />
                </Button>
                {userType === 'admin' && (
                    <Button icon_sm_delete fill onClick={() => this.props.onDelete(id)}>
                        <img src={Delete} alt="delete" />
                    </Button>
                )}
            </div>
        );
    };

    renderUrgency(urgency) {
        const { config, t } = this.props;
        return <DeviationUrgencyMarker config={config} t={t} urgency={urgency} />;
    }

    getStatusOptions(statuses,t){
        if(!statuses) return [];
        const statusesKeys = Object.keys(statuses);
        const options = statusesKeys.map(key => ({ name: t(statuses[key]), code: key }));
        return options;
    }

    render() {
        const { config, t, isUnionMode } = this.props;
        const { filters, appliedFilters, loading } = this.props.deviationStore;
        const { commonStore } = this.props;
        const dateTimeRules =
            commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules
                ? commonStore.config.client.data.dateTimeRules
                : false;

        return (
            <GenericList
                columns={[
                    {
                        Header: 'ID',
                        id: 'id',
                        accessor: 'id',
                        minWidth: 40,
                        maxWidth: 40,
                        isSortable: true,
                    },
                    {
                        Header: 'Project',
                        id: 'project_name',
                        accessor: ({ project_name }) => <span>{project_name}</span>,
                        isSortable: true,
                        maxWidth: 150,
                        minWidth: 120,
                        textAlign: 'start'
                    },
                    {
                        Header: 'Due date',
                        id: 'due_date',
                        accessor: ({ due_date }) => (
                            <span>{due_date ? shortDateFormat(due_date, dateTimeRules) : ''}</span>
                        ),
                        hidden: !!isUnionMode,
                        isSortable: true,
                        maxWidth: 80,
                        minWidth: 80,
                    },
                    {
                        Header: 'Subject',
                        id: 'subject',
                        accessor: ({ urgency, subject, id }) => (
                            <span title={subject}>
                                {this.renderUrgency(urgency)}
                                <NavLink
                                    to={`/admin/deviation/${id}`}
                                    className="list-main-column"
                                    state={{ deviation: id }}
                                >
                                    {subject}
                                </NavLink>
                            </span>
                        ),
                        isSortable: true,
                    },
                    {
                        Header: 'Responsible',
                        id: 'assigned_name',
                        accessor: ({ assigned_name }) => <span>{assigned_name}</span>,
                        hidden: !!isUnionMode,
                        isSortable: true,
                    },
                    {
                        Header: 'Reported by',
                        id: 'reporter_name',
                        accessor: ({ reporter_name }) => <span>{reporter_name}</span>,
                        hidden: !!isUnionMode,
                        isSortable: true,
                    },
                    {
                        Header: 'Status',
                        id: 'status',
                        hidden: !!isUnionMode,
                        accessor: ({ status }) => <span>{config.names[status]}</span>,
                        isSortable: true,
                    },
                    {
                        Header: 'Actions',
                        id: 'actions',
                        accessor: ({ id }) => this.renderTableActionButtons(id),
                        maxWidth:60,
                        minWidth:60
                    },
                ]}
                tableHeader={this.props.t('Deviations')}
                filters={appliedFilters}
                forceReload={this.props.deviationStore.deleteSuccess}
                lastListLoadTime={this.props.deviationStore.lastListLoadTime}
                header={
                    <GenericFilter
                        filters={filters}
                        setFilter={(name, value) => this.props.deviationStore.setFilter(name, value)}
                        onFilter={() => this.props.deviationStore.onFilter()}
                        hasStatusFilter={true}
                        hasProjectFilter={true}
                        hasDeviationReporterFilters={true}
                        searchFilterName={'subject'}
                        statusOptions={this.getStatusOptions(config.names,t)}
                />
                }
                requestData={(params) => this.props.deviationStore.loadList(params)}
            >
                <Button fill wd onClick={() => this.props.onAdd()}>
                    <i className="fa fa-plus-circle fa-xl margin-right-10" />
                    {t('Add new')}
                </Button>
            </GenericList>
        );
    }
}
export default inject(
    'clientStore',
    'commonStore',
    'deviationStore',
    'userStore'
)(applicationRouter(withLocalization(observer(DeviationList))));
